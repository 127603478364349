/* eslint-disable no-alert */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";

// import UploadIcon from "@mui/icons-material/Upload";
import DoneIcon from "@mui/icons-material/Done";
import EditNoteIcon from "@mui/icons-material/EditNote";
import SaveIcon from "@mui/icons-material/Save";
// import ShortcutIcon from "@mui/icons-material/Shortcut";

import { useStateContext } from "contexts/ContextProvider";
import useAxiosPrivate from "contexts/interceptors/axios";
import AttachDocumentModal from "modals/documents/AttachDocument";
import AnnotateDocumentModal from "modals/documents/AnnotateDocumentModal";
import TransmitDocumentModal from "modals/documents/TransmitDocumentModal";
import { enqueueSnackbar } from "notistack";
import DocumentsTable from "../../DocumentsTable";

export default function RoutedDocuments() {
  const { auth } = useStateContext();
  const axiosPrivate = useAxiosPrivate();
  const [documents, setDocuments] = useState([]);
  const [selectedData, setSelectedData] = useState(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [openAttachDocModal, setOpenAttachDocModal] = useState(false);
  const [openAnnotateModal, setOpenAnnotateModal] = useState(false);
  const [openTransmitModal, setOpenTransmitModal] = useState(false);

  // const parsedRole = auth?.role ? JSON.parse(auth?.role) : null;

  const handleGetAll = () => {
    setLoading(true);
    setError("");

    axiosPrivate
      .get(`/documents/getAllDocuments`, {
        params: { unitId: auth.unitId },
      })
      .then((res) => {
        res.data.forEach((doc) => {
          if (typeof doc.action === "string") {
            doc.action = JSON.parse(doc.action);
          }
        });

        const filteredRoutedDocs = res?.data?.filter((doc) => {
          const destinations = JSON.parse(doc?.destinations);

          if (
            doc.status === 9 &&
            destinations.some((dest) => dest.id === auth.unitId)
          ) {
            return true;
          }
          return false;
        });

        // Sort documents by complexity (3 - 1)
        // filteredRoutedDocs.sort((a, b) => b.complexity - a.complexity);

        setDocuments(filteredRoutedDocs);
      })
      .catch((err) => {
        setError(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetAll();
  }, []);

  const handleMarkAsDone = () => {
    const confirmed = window.confirm(
      "Are you sure you want to transmit this document?"
    );

    if (confirmed) {
      setLoading(true);
      setError("");

      axiosPrivate
        .patch(`/documents/markAsDone`, {
          documents: selectedData,
          unit: { id: auth?.unitId, unit: auth?.unitName },
        })
        .then(() => {
          enqueueSnackbar(
            `Document${selectedData.length > 1 ? "s" : ""} marked as done`,
            {
              variant: "success",
            }
          );
          setSelectedData(null);
          handleGetAll();
        })
        .catch((err) => {
          setError(err?.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleSaveDocument = () => {
    setLoading(true);
    setError("");

    axiosPrivate
      .put(`/documents/saveDocument`, {
        documents: selectedData,
        currentOwner: auth?.unitId,
        remarks: `Saved by ${auth?.firstName} ${auth?.lastName} from ${auth?.unitName}`,
      })
      .then(() => {
        handleGetAll();

        enqueueSnackbar("Document Saved", {
          variant: "info",
        });
      })
      .catch((err) => {
        setError(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box
      sx={{
        overflowX: "auto",
        p: "20px",
        width: "100%",
      }}
    >
      <AttachDocumentModal
        open={openAttachDocModal}
        handleClose={() => {
          setSelectedData(null);
          setOpenAttachDocModal(false);
        }}
        loadingState={loading}
        selectedData={selectedData || null}
        updateTableFunction={() => handleGetAll()}
      />
      <AnnotateDocumentModal
        open={openAnnotateModal}
        handleClose={() => {
          setSelectedData(null);
          setOpenAnnotateModal(false);
        }}
        loadingState={loading}
        selectedData={selectedData || null}
        updateTableFunction={() => handleGetAll()}
      />
      <TransmitDocumentModal
        open={openTransmitModal}
        handleClose={() => {
          setSelectedData(null);
          setOpenTransmitModal(false);
        }}
        loadingState={loading}
        selectedData={selectedData || null}
        updateTableFunction={() => handleGetAll()}
      />
      <Box
        sx={{
          textAlign: "center",
          borderRadius: "10px",
          boxShadow: "3px 2px 15px 3px rgba(100, 100, 100, 0.5)",
          // backgroundColor: "#59395c",
          backgroundColor: "#f0f0f0",
          p: "1rem",
          mb: "1rem",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            color: "#59395c",
            textTransform: "uppercase",
            fontSize: "25px",
          }}
        >
          Routed Documents
        </Typography>
      </Box>
      {error && (
        <Box sx={{ backgroundColor: "red", width: "100%", mt: 2, px: 1 }}>
          <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
            {error}
          </Typography>
        </Box>
      )}
      <Box
        borderRadius="10px"
        boxShadow="3px 2px 15px 3px rgba(100, 100, 100, 0.8)"
        p="2rem"
        sx={{ backgroundColor: "#f0f0f0" }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            mt: -0.5,
            minWidth: "30%",
            right: 280,
            zIndex: 1000,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              disabled={
                loading ||
                selectedData?.length === 0 ||
                selectedData?.length > 1 ||
                !selectedData
              }
              onClick={() => setOpenAnnotateModal(true)}
              sx={{
                backgroundColor:
                  loading ||
                  selectedData?.length === 0 ||
                  selectedData?.length > 1 ||
                  !selectedData ||
                  selectedData[0].status === 2
                    ? "lightgray"
                    : "#4b51b1",
                color: "#fff",
                fontSize: "14px",
                fontWeight: "bold",
                padding: "5px 20px",
                borderRadius: "15px",
                mr: 2,
                "&:hover": {
                  backgroundColor: "lightgray",
                  color: "#2f2f2f",
                  fontWeight: "bold",
                },
              }}
            >
              <EditNoteIcon sx={{ mr: 1 }} />
              Annotate
            </Button>

            <Button
              disabled={loading || selectedData?.length === 0 || !selectedData}
              onClick={() => handleMarkAsDone()}
              sx={{
                backgroundColor:
                  loading || selectedData?.length === 0 || !selectedData
                    ? "lightgray"
                    : "#4b51b1",
                color: "#fff",
                fontSize: "14px",
                fontWeight: "bold",
                padding: "5px 20px",
                borderRadius: "15px",
                mr: 2,
                "&:hover": {
                  backgroundColor: "lightgray",
                  color: "#2f2f2f",
                  fontWeight: "bold",
                },
              }}
            >
              <DoneIcon sx={{ mr: 1 }} />
              Mark as done
            </Button>

            <Button
              disabled={
                loading ||
                selectedData?.length === 0 ||
                !selectedData ||
                selectedData[0].status === 2
              }
              onClick={() => handleSaveDocument()}
              sx={{
                backgroundColor:
                  loading ||
                  selectedData?.length === 0 ||
                  !selectedData ||
                  selectedData[0].status === 2
                    ? "lightgray"
                    : "#00a8f3",
                color: "#fff",
                fontSize: "14px",
                fontWeight: "bold",
                padding: "5px 20px",
                borderRadius: "15px",
                mr: 2,
                "&:hover": {
                  backgroundColor: "lightgray",
                  color: "#2f2f2f",
                  fontWeight: "bold",
                },
              }}
            >
              <SaveIcon sx={{ mr: 1 }} />
              Save
            </Button>

            {/* {parsedRole?.some((role) =>
              ["chief", "unit head"].includes(role)
            ) && (
              <Button
                disabled={
                  loading ||
                  selectedData?.length === 0 ||
                  !selectedData ||
                  selectedData[0].status === 2 ||
                  // test
                  !selectedData.every(
                    (item) => item.status === selectedData[0].status
                  )
                }
                onClick={() => setOpenTransmitModal(true)}
                sx={{
                  backgroundColor:
                    loading ||
                    selectedData?.length === 0 ||
                    !selectedData ||
                    selectedData[0].status === 2
                      ? "lightgray"
                      : "#0e6b95",
                  color: "#fff",
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "5px 20px",
                  borderRadius: "15px",
                  mr: 2,
                  "&:hover": {
                    backgroundColor: "lightgray",
                    color: "#2f2f2f",
                    boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
                  },
                }}
              >
                <ShortcutIcon sx={{ mr: 1 }} />
                Transmit
              </Button>
            )} */}

            {/* <Button
              disabled={
                loading ||
                selectedData?.length === 0 ||
                !selectedData ||
                selectedData[0]?.status === 2
              }
              onClick={() => setOpenAttachDocModal(true)}
              sx={{
                backgroundColor:
                  loading ||
                  selectedData?.length === 0 ||
                  !selectedData ||
                  selectedData[0]?.status === 2
                    ? "lightgray"
                    : "green",
                color: "#fff",
                fontSize: "14px",
                fontWeight: "bold",
                padding: "5px 20px",
                borderRadius: "15px",
                mr: 2,
                "&:hover": {
                  backgroundColor: "lightgray",
                  color: "#2f2f2f",
                  boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
                },
              }}
            >
              <UploadIcon sx={{ mr: 1 }} />
              Attach a document
            </Button> */}
          </Box>
        </Box>

        <Box
          sx={{
            mt: 2,
            minWidth: "100%",
          }}
        >
          <DocumentsTable
            data={documents}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            showCheckbox
            showMultipleSelection
            loadingState={loading}
            setLoadingState={setLoading}
            updateTableFunction={handleGetAll}
          />
        </Box>
      </Box>
    </Box>
  );
}
