/* eslint-disable no-alert */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";

import EditNoteIcon from "@mui/icons-material/EditNote";
// import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import ShortcutIcon from "@mui/icons-material/Shortcut";

import { useStateContext } from "contexts/ContextProvider";
import useAxiosPrivate from "contexts/interceptors/axios";
import RouteDocumentModal from "modals/documents/RouteDocumentModal";
import AnnotateDocumentModal from "modals/documents/AnnotateDocumentModal";
import TransmitDocumentModal from "modals/documents/TransmitDocumentModal";
import DocumentsTable from "../../DocumentsTable";

export default function RoutingDocuments() {
  const { auth, routingDocs, setRoutingDocs } = useStateContext();
  const axiosPrivate = useAxiosPrivate();
  const [selectedData, setSelectedData] = useState(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [openRouteModal, setOpenRouteModal] = useState(false);
  const [openTransmitModal, setOpenTransmitModal] = useState(false);
  const [openAnnotateModal, setOpenAnnotateModal] = useState(false);

  const handleGetAll = () => {
    setLoading(true);
    setError("");

    axiosPrivate
      .get(`/documents/getAllDocuments`, {
        params: { unitId: auth.unitId },
      })
      .then((res) => {
        res.data.forEach((doc) => {
          if (typeof doc.action === "string") {
            doc.action = JSON.parse(doc.action);
          }
        });

        const filteredRoutingDocs = res.data.filter((doc) => {
          const destinations = JSON.parse(doc.destinations);

          if (
            destinations.some((dest) => dest.id === auth.unitId) &&
            // doc.currentOwner !== auth.unitId &&
            doc.status === 8 &&
            doc.acceptStatus === 1
          ) {
            return true;
          }
          return false;
        });

        // Sort documents by complexity (3 - 1)
        filteredRoutingDocs.sort((a, b) => b.complexity - a.complexity);

        setRoutingDocs(filteredRoutingDocs);
      })
      .catch((err) => {
        setError(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetAll();
  }, []);

  // font "Mont"

  return (
    <Box
      sx={{
        overflowX: "auto",
        p: "20px",
        width: "100%",
      }}
    >
      <TransmitDocumentModal
        open={openTransmitModal}
        handleClose={() => {
          setSelectedData(null);
          setOpenTransmitModal(false);
        }}
        loadingState={loading}
        selectedData={selectedData || null}
        updateTableFunction={() => handleGetAll()}
      />
      <AnnotateDocumentModal
        open={openAnnotateModal}
        handleClose={() => {
          setSelectedData(null);
          setOpenAnnotateModal(false);
        }}
        loadingState={loading}
        selectedData={selectedData || null}
        updateTableFunction={() => handleGetAll()}
      />
      <RouteDocumentModal
        open={openRouteModal}
        handleClose={() => {
          setSelectedData(null);
          setOpenRouteModal(false);
        }}
        loadingState={loading}
        selectedData={selectedData || null}
        updateTableFunction={() => handleGetAll()}
      />
      <Box
        sx={{
          textAlign: "center",
          borderRadius: "10px",
          boxShadow: "3px 2px 15px 3px rgba(100, 100, 100, 0.5)",
          // backgroundColor: "#59395c",
          backgroundColor: "#f0f0f0",
          p: "1rem",
          mb: "1rem",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            color: "#59395c",
            textTransform: "uppercase",
            fontSize: "25px",
          }}
        >
          Documents for Routing
        </Typography>
      </Box>
      {error && (
        <Box sx={{ backgroundColor: "red", width: "100%", mt: 2, px: 1 }}>
          <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
            {error}
          </Typography>
        </Box>
      )}
      <Box
        borderRadius="10px"
        boxShadow="3px 2px 15px 3px rgba(100, 100, 100, 0.8)"
        p="2rem"
        sx={{ backgroundColor: "#f0f0f0" }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            mt: -0.5,
            minWidth: "30%",
            right: 280,
            zIndex: 1000,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {auth?.unitId === 1 || auth?.unitId === 2 ? (
              <>
                <Button
                  disabled={
                    loading ||
                    selectedData?.length === 0 ||
                    !selectedData ||
                    selectedData[0]?.status === 2
                  }
                  onClick={() => setOpenRouteModal(true)}
                  sx={{
                    backgroundColor:
                      loading ||
                      selectedData?.length === 0 ||
                      !selectedData ||
                      selectedData[0]?.status === 2
                        ? "lightgray"
                        : "#00a052",
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "5px 20px",
                    borderRadius: "15px",
                    mr: 2,
                    "&:hover": {
                      backgroundColor: "lightgray",
                      color: "#2f2f2f",
                      fontWeight: "bold",
                    },
                  }}
                >
                  <ShortcutIcon sx={{ mr: 1 }} />
                  Route
                </Button>
                {/* <Button
                  disabled={
                    loading ||
                    selectedData?.length === 0 ||
                    selectedData?.length > 1 ||
                    !selectedData ||
                    selectedData[0]?.status === 2
                  }
                  // onClick={() => handleReturn()}
                  sx={{
                    backgroundColor:
                      loading ||
                      selectedData?.length === 0 ||
                      selectedData?.length > 1 ||
                      !selectedData ||
                      selectedData[0]?.status === 2
                        ? "lightgray"
                        : "#da2c43",
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "5px 20px",
                    borderRadius: "15px",
                    mr: 2,
                    "&:hover": {
                      backgroundColor: "lightgray",
                      color: "#2f2f2f",
                      fontWeight: "bold",
                    },
                  }}
                >
                  <KeyboardReturnIcon sx={{ mr: 1 }} />
                  Decline
                </Button> */}
              </>
            ) : (
              <>
                <Button
                  disabled={
                    loading ||
                    selectedData?.length === 0 ||
                    selectedData?.length > 1 ||
                    !selectedData ||
                    selectedData[0].status === 2
                  }
                  onClick={() => setOpenAnnotateModal(true)}
                  sx={{
                    backgroundColor:
                      loading ||
                      selectedData?.length === 0 ||
                      selectedData?.length > 1 ||
                      !selectedData ||
                      selectedData[0].status === 2
                        ? "lightgray"
                        : "#4b51b1",
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "5px 20px",
                    borderRadius: "15px",
                    mr: 2,
                    "&:hover": {
                      backgroundColor: "lightgray",
                      color: "#2f2f2f",
                      fontWeight: "bold",
                    },
                  }}
                >
                  <EditNoteIcon sx={{ mr: 1 }} />
                  Annotate
                </Button>
                <Button
                  disabled={
                    loading ||
                    selectedData?.length === 0 ||
                    !selectedData ||
                    selectedData[0].status === 2
                  }
                  onClick={() => setOpenTransmitModal(true)}
                  sx={{
                    backgroundColor:
                      loading ||
                      selectedData?.length === 0 ||
                      !selectedData ||
                      selectedData[0].status === 2
                        ? "lightgray"
                        : "#0e6b95",
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "5px 20px",
                    borderRadius: "15px",
                    mr: 2,
                    "&:hover": {
                      backgroundColor: "lightgray",
                      color: "#2f2f2f",
                      boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
                    },
                  }}
                >
                  <ShortcutIcon sx={{ mr: 1 }} />
                  Transmit
                </Button>
              </>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            mt: 2,
            minWidth: "100%",
          }}
        >
          <DocumentsTable
            data={routingDocs}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            showCheckbox
            singleSelect
            loadingState={loading}
            setLoadingState={setLoading}
            updateTableFunction={handleGetAll}
          />
        </Box>
      </Box>
    </Box>
  );
}
