import React, { useEffect, useState } from "react";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useStateContext } from "contexts/ContextProvider";

dayjs.extend(utc);
dayjs.extend(timezone);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // height: "60vh",
  // width: "40vw",
  background:
    "linear-gradient(170deg, #2b2b2b, #686868, #2b2b2b, #f0f0f0, #f0f0f0, #ffffff)",
  boxShadow: "3px 2px 20px 3px rgba(0, 0, 0, 0.3)",
  borderRadius: "10px",
  overflowY: "auto",
  p: 6,
};

export default function ViewSignatureModal({
  open,
  handleClose,
  dataFromActions,
}) {
  const { BASE_URL } = useStateContext();

  const [rowData, setRowData] = useState();

  const [sign, setSign] = useState();

  const [initial, setInitial] = useState();

  useEffect(() => {
    if (dataFromActions) {
      setRowData(
        Array.isArray(dataFromActions) && dataFromActions.length > 0
          ? dataFromActions[0]
          : dataFromActions
      );
    }
  }, [dataFromActions]);

  useEffect(() => {
    if (rowData) {
      const safeJsonParse = (dataToParse, fallback = null) => {
        try {
          return JSON.parse(dataToParse);
        } catch (err) {
          console.error("Invalid JSON format:", err);
          return fallback;
        }
      };

      const parsedSignPath = rowData.signPath
        ? safeJsonParse(rowData.signPath)
        : null;

      const signaturePath = parsedSignPath?.[0]?.sign || null;
      const initialPath = parsedSignPath?.[0]?.initial || null;

      setSign(signaturePath ? `${BASE_URL}${signaturePath}` : null);
      setInitial(initialPath ? `${BASE_URL}${initialPath}` : null);
    }
  }, [rowData]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Box
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            cursor: "pointer",
            zIndex: 100,
          }}
        >
          <IconButton onClick={handleClose} sx={{ color: "#fff", p: 0 }}>
            <CancelIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "block",
            mb: 2,
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                display: "block",
                textAlign: "center",
                mr: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "300px",
                  width: "300px",
                  backgroundColor: "#fff",
                  border: "solid 1px gray",
                  mb: 2,
                }}
              >
                {sign ? (
                  <img
                    src={sign}
                    alt="signature"
                    style={{
                      width: "100%",
                    }}
                  />
                ) : (
                  <p>No Signature</p>
                )}
              </Box>
              <Typography sx={{ fontSize: "15px" }}>Signature of:</Typography>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "#59395c",
                }}
              >
                {`${rowData?.firstName} ${
                  rowData?.middleIntl && `${rowData?.middleIntl}. `
                }${rowData?.lastName}`}
              </Typography>
              {/* <Typography
              variant="p"
              sx={{ fontSize: "15px", color: "gray", mb: 2 }}
            >
              {rowData.unitName}
            </Typography> */}
            </Box>
            <Box
              sx={{
                display: "block",
                textAlign: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "300px",
                  width: "300px",
                  backgroundColor: "#fff",
                  border: "solid 1px gray",
                  mb: 2,
                }}
              >
                {initial ? (
                  <img
                    src={initial}
                    alt="signature"
                    style={{
                      width: "100%",
                    }}
                  />
                ) : (
                  <p>No Initial</p>
                )}
              </Box>
              <Typography sx={{ fontSize: "15px" }}>Initial of:</Typography>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "#59395c",
                }}
              >
                {`${rowData?.firstName} ${
                  rowData?.middleIntl && `${rowData?.middleIntl}. `
                }${rowData?.lastName}`}
              </Typography>
              {/* <Typography
              variant="p"
              sx={{ fontSize: "15px", color: "gray", mb: 2 }}
            >
              {rowData.unitName}
            </Typography> */}
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
