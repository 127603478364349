/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import LoginValidation, { initialLog } from "schema/login";
import "./login.css";
import { useStateContext } from "contexts/ContextProvider";

import useAxiosPrivate from "contexts/interceptors/axios";
// import AnnouncementModal from "modals/miscellaneous/AnnouncementModal";
import DepedLogo from "../../assets/images/deped_logo.png";
import bgImage from "../../assets/images/deped_facade.jpg";

export default function LoginPage() {
  const { setAuth } = useStateContext();
  const axiosPrivate = useAxiosPrivate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const navigate = useNavigate();
  const theme = useTheme();

  const [showPassword, setShowPassword] = useState(false);
  // const [openAnnouncementModal, setOpenAnnouncementModal] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const formik = useFormik({
    initialValues: initialLog,
    validationSchema: LoginValidation,
    onSubmit: async () => {
      setLoading(true);
      setError("");
      await axiosPrivate
        .post("/user/login", formik?.values, {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        })
        .then((res) => {
          // setOpenAnnouncementModal(true);
          const { data } = res;
          // const { accessToken } = res.data.data;

          // axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

          localStorage.setItem("authInfo", JSON.stringify(data?.data));

          if (data?.valid) {
            setAuth(data?.data);
            navigate("/dashboard");
          }
        })
        .catch((err) => {
          let message = "";
          if (err?.response?.status === 404) {
            message = "Invalid Credentials";
          } else if (err?.response?.status === 401) {
            message = err?.response?.data?.error;
          } else {
            message = "Internal Server Error";
          }
          setError(message || err?.message);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
        minHeight: "550px",
        width: "100vw",
        overflow: "auto",
        // overflowX: "hidden",
        scrollbarWidth: "thin",
        msOverflowStyle: "none",
        position: "relative",
        "&::-webkit-scrollbar": {
          width: "0.5rem",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#53FDFD",
        },
      }}
    >
      {/* <AnnouncementModal
        open={openAnnouncementModal}
        handleClose={() => setOpenAnnouncementModal(false)}
      /> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
          // background:
          //   "linear-gradient(40deg, rgba(239, 98, 98, 0.9), rgba(59, 152, 184, 1), rgba(26, 214, 164, 0.9))",
          backgroundAttachment: "fixed",
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          backgroundRepeat: "no-repeat",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        />
        <Box
          variant="form"
          component="form"
          className="login-form"
          onSubmit={formik.handleSubmit}
          autoComplete="off"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            background:
              "linear-gradient(-150deg, #ffffff, #f0f0f0, #ececec, #d4d4d4, #959595)",
            // background:
            //   "linear-gradient(50deg, rgba(248, 242, 240, 0.9), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(180, 180, 180, 0.9))",
            borderRadius: "20px",
            boxShadow: "3px 2px 20px 3px rgba(0, 0, 0, 0.3)",
            padding: 4,
            height: "500px",
            // minHeight: "400px",
            width: "500px",
            zIndex: 1000,
            "@media (max-width: 720px)": {
              width: "80vw",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              // border: "solid 1px black",
              width: "100%",
              mb: 4,
            }}
          >
            <Box
              component="img"
              variant="img"
              src={DepedLogo}
              sx={{
                my: 1,
                height: "150px",
                width: "150px",
                "@media (max-width: 510px)": {
                  height: "100px",
                  width: "100px",
                },
              }}
            />
            <Divider
              sx={{
                mx: 4,
                background: "black",
                height: "100%",
                px: 0.2,
                "@media (max-width: 400px)": {
                  display: "none",
                },
              }}
            />
            <Box
              sx={{
                display: "block",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "noWrap",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#59395c",
                    height: "50px",
                    width: "50px",
                    mr: "5px",
                    "@media (max-width: 510px)": {
                      height: "40px",
                      width: "40px",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontWeight: "500",
                      fontSize: "40px",
                      color: "#fff",
                      letterSpacing: "5px",
                      "@media (max-width: 510px)": {
                        fontSize: "30px",
                      },
                    }}
                  >
                    L
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontWeight: "500",
                    fontSize: "40px",
                    color: "#59395c",
                    letterSpacing: "5px",
                    "@media (max-width: 510px)": {
                      fontSize: "30px",
                    },
                  }}
                >
                  ess
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "noWrap",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#59395c",
                    height: "50px",
                    width: "50px",
                    mr: "5px",
                    "@media (max-width: 510px)": {
                      height: "40px",
                      width: "40px",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontWeight: "500",
                      fontSize: "40px",
                      color: "#fff",
                      letterSpacing: "5px",
                      "@media (max-width: 510px)": {
                        fontSize: "30px",
                      },
                    }}
                  >
                    P
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontWeight: "500",
                    fontSize: "40px",
                    color: "#59395c",
                    letterSpacing: "5px",
                    "@media (max-width: 510px)": {
                      fontSize: "30px",
                    },
                  }}
                >
                  aper
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "noWrap",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#59395c",
                    height: "50px",
                    width: "50px",
                    mr: "5px",
                    "@media (max-width: 510px)": {
                      height: "40px",
                      width: "40px",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontWeight: "500",
                      fontSize: "40px",
                      color: "#fff",
                      letterSpacing: "5px",
                      "@media (max-width: 510px)": {
                        fontSize: "30px",
                      },
                    }}
                  >
                    S
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontWeight: "500",
                    fontSize: "40px",
                    color: "#59395c",
                    letterSpacing: "5px",
                    "@media (max-width: 510px)": {
                      fontSize: "30px",
                    },
                  }}
                >
                  ystem
                </Typography>
              </Box>
            </Box>
          </Box>
          <Divider
            className="colorchange"
            sx={{
              // backgroundColor: "blue",
              width: "100%",
              py: 0.5,
              borderRadius: "10px",
              mb: 2,
            }}
          />
          {error && (
            <Box sx={{ backgroundColor: "red", width: "100%", mt: 2, px: 1 }}>
              <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                {error}
              </Typography>
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              // mb: 2,
            }}
          >
            <TextField
              id="username"
              placeholder="Username"
              variant="outlined"
              size="small"
              disabled={loading}
              value={formik.values.username}
              onChange={formik.handleChange}
              onBlur={formik.handleBLur}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
              sx={{
                mb: 2,
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#59395c",
                  // borderColor: "#2f2f2f",
                  // borderColor: "#f6e247",
                  // borderColor: "#fff",
                  transition: "border-color 0.2s ease-in-out",
                },
              }}
              InputProps={{
                style: {
                  color: "#59395c",
                  // color: "#2f2f2f",
                  // color: "#f6e247",
                  // color: "#fff",
                  fontWeight: "bold",
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle
                      sx={{
                        color: "#59395c",
                        // color: "#2f2f2f",
                        // color: "#f6e247",
                        // color: "#fff",
                        borderRadius: "2px 0px 0px 2px",
                        height: "30px",
                        width: "30px",
                        mr: "10px",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              id="password"
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              variant="outlined"
              size="small"
              disabled={loading}
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBLur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              sx={{
                mb: 4,
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#59395c",
                  // borderColor: "#2f2f2f",
                  // borderColor: "#f6e247",
                  // borderColor: "#fff",
                  transition: "border-color 0.2s ease-in-out",
                },
                "& .MuiInputLabel-root": {
                  color: "#59395c",
                  // color: "#2f2f2f",
                  // color: "#f6e247",
                  // color: "#fff",
                },
              }}
              InputProps={{
                style: {
                  color: "#59395c",
                  // color: "#2f2f2f",
                  // color: "#f6e247",
                  // color: "#fff",
                  fontWeight: "bold",
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon
                      sx={{
                        color: "#59395c",
                        // color: "#2f2f2f",
                        // color: "#f6e247",
                        // color: "#fff",
                        borderRadius: "2px 0px 0px 2px",
                        height: "30px",
                        width: "30px",
                        mr: "10px",
                      }}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    onKeyPress={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <VisibilityIcon
                        size={18}
                        sx={{
                          color: "#59395c",
                          // color: "#2f2f2f",
                          // color: "#f6e247",
                          // color: "#fff",
                        }}
                      />
                    ) : (
                      <VisibilityOffIcon
                        size={18}
                        sx={{
                          color: "#59395c",
                          // color: "#2f2f2f",
                          // color: "#f6e247",
                          // color: "#fff",
                        }}
                      />
                    )}
                  </IconButton>
                ),
              }}
            />

            <Button
              type="submit"
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                background: "#0f8548",
                color: "#fff",
                transition: "ease-in-out 0.3s",
                padding: "8px",
                width: "100%",
                minWidth: "100px",
                borderRadius: "10px",
                m: "auto",
                "&:hover": {
                  backgroundColor: "#2f2f2f",
                },
              }}
            >
              <Typography
                sx={{
                  fontWeight: "900",
                  // fontFamily: "Roboto",
                  fontSize: "18px",
                  letterSpacing: "3px",
                }}
              >
                Login
              </Typography>
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            position: "absolute",
            height: "100%",
            width: "100%",
            background: "rgba(255, 236, 172, 0.6)",
            boxShadow: "3px 2px 20px 3px rgba(65, 65, 65, 0.7)",
            clipPath: "polygon(0 0, 0 50%, 15% 0)",
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            position: "absolute",
            height: "100%",
            width: "100%",
            background: "rgba(135, 122, 77, 0.8)",
            boxShadow: "3px 2px 20px 3px rgba(65, 65, 65, 0.7)",
            clipPath: "polygon(0 100%, 0 60%, 10% 100%)",
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            position: "absolute",
            height: "100%",
            width: "100%",
            background: "rgba(255, 236, 172, 0.8)",
            boxShadow: "3px 2px 20px 3px rgba(65, 65, 65, 0.7)",
            clipPath: "polygon(85% 0, 100% 50%, 100% 0)",
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            position: "absolute",
            height: "100%",
            width: "100%",
            background: "rgba(135, 122, 77, 0.8)",
            boxShadow: "3px 2px 20px 3px rgba(65, 65, 65, 0.7)",
            clipPath: "polygon(90% 100%, 100% 60%, 100% 100%)",
          }}
        />
      </Box>
      {/* <Box
        sx={{
          backgroundColor: "#2f2f2f",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "5%",
          minHeight: "50px",
          zIndex: 1000,
        }}
      /> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#2f2f2f",
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
          height: "5%",
          minHeight: "30px",
          zIndex: 1000,
        }}
      >
        <Typography
          sx={{
            fontSize: "14px",
            // color: "#59395c",
            // color: "#2f2f2f",
            color: "#fff",
            fontWeight: "bold",
          }}
        >
          © DepEd Imus Division | 2024.
        </Typography>
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          right: 0,
          zIndex: 1001,
        }}
      >
        <Typography
          sx={{
            fontSize: "10px",
            color: "lightgray",
          }}
        >
          Patch 10092024-003
        </Typography>
      </Box>
    </Box>
  );
}
