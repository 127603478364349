import DashboardIcon from "@mui/icons-material/Dashboard";

import SummarizeIcon from "@mui/icons-material/Summarize";

import { AiFillSignature } from "react-icons/ai";
// import DownloadDoneOutlinedIcon from "@mui/icons-material/DownloadDoneOutlined";
import DownloadIcon from "@mui/icons-material/FileDownload";
import DocuTypeIcon from "@mui/icons-material/InsertDriveFile";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import OfficeIcon from "@mui/icons-material/HomeWork";
import PanToolIcon from "@mui/icons-material/PanTool";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import { PiOfficeChairFill } from "react-icons/pi";
import SaveIcon from "@mui/icons-material/Save";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import { TbPencilCheck } from "react-icons/tb";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import UploadIcon from "@mui/icons-material/Upload";
import UsersIcon from "@mui/icons-material/PeopleAlt";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const links = [
  {
    title: "Home",
    role: "superadmin",
    links: [
      {
        name: "Dashboard",
        path: "dashboard",
        icon: <DashboardIcon />,
      },
    ],
  },

  {
    title: "For Signature",
    role: "bosses",
    links: [
      {
        name: "For Signature",
        path: "signature",
        icon: <AiFillSignature style={{ fontSize: "26px" }} />,
      },
    ],
  },

  {
    title: "For Routing",
    role: "otherThanBosses",
    links: [
      {
        name: "Routing",
        path: "routed",
        icon: <SummarizeIcon />,
      },
    ],
  },

  {
    title: "Documents",
    role: "receiver",
    links: [
      {
        name: "For Routing",
        path: "routing",
        icon: <ShortcutIcon sx={{ fontSize: "26px" }} />,
      },
      {
        name: "For Signature",
        path: "signature",
        icon: <AiFillSignature style={{ fontSize: "26px" }} />,
      },
      {
        name: "Routed",
        path: "routedDocs",
        icon: <SummarizeIcon sx={{ fontSize: "26px" }} />,
      },
      {
        name: "Signed",
        path: "signedDocs",
        icon: <TbPencilCheck sx={{ fontSize: "26px" }} />,
      },
    ],
  },

  {
    title: "Documents For Release",
    role: "secretary",
    links: [
      {
        name: "Release",
        path: "release",
        icon: <ThumbUpIcon sx={{ fontSize: "26px" }} />,
      },
    ],
  },

  {
    title: "Documents",
    role: "sender",
    links: [
      {
        name: "Incoming",
        path: "incoming",
        icon: <DownloadIcon />,
      },
      {
        name: "Outgoing",
        path: "outgoing",
        icon: <UploadIcon />,
      },
      {
        name: "Pending",
        path: "pending",
        icon: <PendingActionsIcon />,
      },
      {
        name: "Saved",
        path: "saved",
        icon: <SaveIcon />,
      },
      {
        name: "Lapsed",
        path: "lapsed",
        icon: <WarningAmberIcon />,
      },
      {
        name: "On-Hold",
        path: "onhold",
        icon: <PanToolIcon />,
      },
      {
        name: "Signed",
        path: "signed",
        icon: <TbPencilCheck style={{ fontSize: "26px" }} />,
      },
      {
        name: "Uploaded",
        path: "uploaded",
        icon: <FileCopyIcon style={{ fontSize: "26px" }} />,
      },
    ],
  },

  {
    title: "",
    role: "superintendents",
    links: [
      {
        name: "Incoming",
        path: "incomingDocs",
        icon: <DownloadIcon />,
      },
      {
        name: "Outgoing",
        path: "outgoingDocs",
        icon: <UploadIcon />,
      },
    ],
  },

  {
    title: "Libraries",
    role: "admin",
    links: [
      {
        name: "Document Types",
        path: "doc-types",
        icon: <DocuTypeIcon />,
      },
      {
        name: "Offices",
        path: "offices",
        icon: <OfficeIcon />,
      },
      {
        name: "Units",
        path: "units",
        icon: <PiOfficeChairFill />,
      },
      {
        name: "Users",
        path: "users",
        icon: <UsersIcon />,
      },
    ],
  },
];

export default links;
