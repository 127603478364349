import React, { createContext, useContext, useState, useMemo } from "react";
import PropTypes from "prop-types";

const StateContext = createContext();

export function ContextProvider({ children }) {
  const storedAuth = localStorage.getItem("authInfo");
  const [auth, setAuth] = useState(JSON.parse(storedAuth) || null);
  const [routingDocs, setRoutingDocs] = useState([]);
  const [selectedUserType, setSelectedUserType] = useState("");
  const [usersRelatedUnits, setUsersRelatedUnits] = useState("");

  // const BASE_URL = "http://localhost:9000";
  // const BASE_URL = "http://172.16.0.27:8000";
  const BASE_URL = "https://lesspaper.depedimuscity.com:8000";

  const contextValue = useMemo(
    () => ({
      auth,
      setAuth,
      selectedUserType,
      setSelectedUserType,
      usersRelatedUnits,
      setUsersRelatedUnits,
      routingDocs,
      setRoutingDocs,
      BASE_URL,
    }),
    [
      auth,
      setAuth,
      selectedUserType,
      setSelectedUserType,
      usersRelatedUnits,
      setUsersRelatedUnits,
      routingDocs,
      setRoutingDocs,
    ]
  );

  return (
    <StateContext.Provider value={contextValue}>
      {children}
    </StateContext.Provider>
  );
}

export const useStateContext = () => useContext(StateContext);

ContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
