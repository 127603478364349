/* eslint-disable no-param-reassign */
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Modal,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Close";
import RemoveIcon from "@mui/icons-material/Remove";
import UploadIcon from "@mui/icons-material/Upload";

import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { array, number, object, string } from "yup";
import SelectOffice from "components/Textfields/SelectOffice";
import SelectRole from "components/Textfields/SelectRole";
import SelectUnit from "components/Textfields/SelectUnit";
import useAxiosPrivate from "contexts/interceptors/axios";
import { enqueueSnackbar } from "notistack";

const style = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  position: "absolute",
  backgroundColor: "#f0f0f0",
  // background:
  //   "linear-gradient(40deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8))",
  //   border: "solid 2px #46e3be",
  boxShadow: "3px 2px 20px 3px rgba(0, 0, 0, 0.3)",
  borderRadius: "10px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflow: "auto",
  "@media (min-width: 10px)": {
    height: "90vh",
    width: "85vw",
  },

  "@media (min-width: 480px)": {
    height: "85vh",
    width: "80vw",
  },

  "@media (min-width: 640px)": {
    height: "75vh",
    width: "70vw",
  },

  "@media (min-width: 768px)": {
    height: "75vh",
    width: "70vw",
  },

  "@media (min-width: 1024px)": {
    height: "80vh",
    width: "70vw",
  },

  "@media (min-width: 1082px)": {
    height: "80vh",
    width: "65vw",
  },
};

export default function AddUserModal({
  open,
  handleClose,
  updateTableFunction,
}) {
  const axiosPrivate = useAxiosPrivate();

  const [sign, setSign] = useState();
  const [signError, setSignError] = useState();
  const [signImg, setSignImg] = useState();

  const [initial, setInitial] = useState();
  const [initialError, setInitialError] = useState();
  const [initialImg, setInitialImg] = useState();

  const [positions, setPositions] = useState([""]);
  const [positionErrorMssg, setPositionErrorMssg] = useState([]);
  const [userRelatedUnits, setUserRelatedUnits] = useState([
    { id: null, unit: null },
  ]);
  const [unitErrorMssg, setUnitErrorMssg] = useState([]);

  const [disabled, setDisabled] = useState(false);
  const [disableSignUpload, setDisableSignUpload] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      username: "",
      role: [],
      officeId: "",
      unitId: "",
    },

    validationSchema: object().shape({
      firstName: string().required("Required"),
      lastName: string().required("Required"),
      username: string().required("Required"),
      role: array().required("Required"),
      officeId: number().nullable(),
      unitId: number().nullable(),
    }),
    onSubmit: (values) => {
      setLoading(true);
      setError("");

      const formData = new FormData();

      if (positions[0] !== "") {
        formData.append("positions", JSON.stringify(positions));
      } else {
        formData.append("positions", JSON.stringify(["N/A"]));
      }

      if (userRelatedUnits && userRelatedUnits[0]?.id !== null) {
        formData.append("relatedUnits", JSON.stringify(userRelatedUnits));
      }

      if (sign) {
        formData.append("sign", sign);
      }

      if (initial) {
        formData.append("initial", initial);
      }

      Object.keys(values).forEach((key) => {
        if (key === "role") {
          return formData.append(key, JSON.stringify(values[key]));
        }
        if (values[key]) {
          return formData.append(key, values[key]);
        }

        return null;
      });

      axiosPrivate
        .post("/user/register", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          enqueueSnackbar("User Added", {
            variant: "success",
          });
          updateTableFunction();
          handleClose();
          formik.resetForm();
          setPositions([""]);
          setSign("");
          setSignError("");
          setSignImg("");
          setInitial("");
          setInitialError("");
          setInitialImg("");
        })
        .catch((err) => {
          setError(err?.message);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const displayUnitError = (id, text) => {
    setUnitErrorMssg((prevErrors) => ({
      ...prevErrors,
      [id]: text,
    }));
    setTimeout(() => {
      setUnitErrorMssg((prevErrors) => ({
        ...prevErrors,
        [id]: "",
      }));
    }, 3000);
  };

  const displayPositionError = (id, text) => {
    setPositionErrorMssg((prevErrors) => ({
      ...prevErrors,
      [id]: text,
    }));
    setTimeout(() => {
      setPositionErrorMssg((prevErrors) => ({
        ...prevErrors,
        [id]: "",
      }));
    }, 3000);
  };

  const handleSignUpload = async (event) => {
    try {
      setLoading(true);
      setSignError("");

      const file = event.target.files[0];

      if (file && file.type === "image/png") {
        const img = new Image();
        const objectUrl = URL.createObjectURL(file);

        img.onload = () => {
          if (img.height <= 500) {
            const reader = new FileReader();
            reader.onloadend = () => {
              setSignImg(reader.result);
            };
            reader.readAsDataURL(file);

            setSign(file); // Set the file state
            setSignError("");
          } else {
            setSign(null);
            setSignError("Image height must be 500px or less.");
            setSignImg("");
          }
          URL.revokeObjectURL(objectUrl); // Clean up the object URL
        };

        img.onerror = () => {
          setSignError("Error: Invalid image file.");
          setSign(null);
          URL.revokeObjectURL(objectUrl);
        };

        img.src = objectUrl; // Set the source to start loading the image
      } else {
        setSignError("Error: Only PNG files are accepted.");
        setSign(null);
      }
    } catch (err) {
      setSignError("Error: Invalid PNG file.");
      setSign(null);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleInitialUpload = async (event) => {
    try {
      setLoading(true);
      setInitialError("");

      const file = event.target.files[0];

      if (file && file.type === "image/png") {
        const img = new Image();
        const objectUrl = URL.createObjectURL(file);

        img.onload = () => {
          if (img.height <= 500) {
            const reader = new FileReader();
            reader.onloadend = () => {
              setInitialImg(reader.result);
            };
            reader.readAsDataURL(file);

            setInitial(file);
            setInitialError("");
          } else {
            setInitial(null);
            setInitialError("Image height must be 500px or less.");
            setInitialImg("");
          }
          URL.revokeObjectURL(objectUrl); // Clean up the object URL
        };

        img.onerror = () => {
          setInitialError("Error: Invalid image file.");
          setInitial(null);
          URL.revokeObjectURL(objectUrl);
        };

        img.src = objectUrl; // Set the source to start loading the image
      } else {
        setInitialError("Error: Only PNG files are accepted.");
        setInitial(null);
      }
    } catch (err) {
      setInitialError("Error: Invalid PNG file.");
      setInitial(null);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleRelatedUnits = (index, value) => {
    setUserRelatedUnits((prevResponse) => {
      const newRelatedUnits = [...prevResponse];
      const destsIds = newRelatedUnits.map((dest) => dest.id);

      if (destsIds.includes(value.id)) {
        newRelatedUnits[index] = { id: null, unit: null };
        displayUnitError(index, "Unit already chosen");
      } else {
        newRelatedUnits[index] = value || { id: null, unit: null };
      }
      return newRelatedUnits;
    });
  };

  const handlePositions = (index, value) => {
    setPositions((prevResponse) => {
      const newPosition = [...prevResponse];

      if (!value) {
        displayPositionError(index, "Position Required");
      }

      if (newPosition.includes(value)) {
        if (value) {
          displayPositionError(index, "Position already exists");
        }
      }

      newPosition[index] = value || "";
      return newPosition;
    });
  };

  useEffect(() => {
    let valuesToCheck;

    if (formik?.values?.role === "admin") {
      const { middleIntl, officeId, unitId, ...otherValues } = formik.values;
      valuesToCheck = otherValues;

      formik.values.officeId = null;
      formik.values.unitId = null;
    } else {
      const { middleIntl, ...otherValues } = formik.values;

      valuesToCheck = otherValues;
    }

    const areAllValuesFilled = Object.values(valuesToCheck).every(
      (value) => !!value
    );

    // let signUploaded = false;

    const isSignUploadDisabled = ![
      "sds",
      "asds",
      "chief",
      "unit head",
    ].includes(formik?.values?.role);

    // if (!isSignUploadDisabled) {
    //   if (signImg && initialImg) {
    //     signUploaded = true;
    //   }
    // } else {
    //   signUploaded = true;
    // }

    setDisabled(!areAllValuesFilled);

    setDisableSignUpload(isSignUploadDisabled);
  }, [formik.values, signImg, initialImg]);

  const components = [
    <TextField
      name="username"
      label="Username"
      size="small"
      disabled={loading}
      value={formik.values.username}
      onChange={formik.handleChange}
      onBlur={formik.handleBLur}
      error={formik.touched.username && Boolean(formik.errors.username)}
      helperText={formik.touched.username && formik.errors.username}
      variant="outlined"
      fullWidth
    />,

    <SelectRole
      label="Role"
      name="role"
      width="100%"
      // disabled={loading}
      value={formik.values.role}
      onChange={(fieldName, selectedValue) => {
        formik.setFieldValue("role", selectedValue);

        if (
          !selectedValue ||
          selectedValue === "admin" ||
          selectedValue === "secretary" ||
          selectedValue === "unit employee"
        ) {
          setSignImg("");
          setSign("");
          setSignError("");
          setInitialImg("");
          setInitial("");
          setInitialError("");
          setPositions([""]);
        }
      }}
      onBlur={formik.handleBLur}
      error={formik.touched.role && Boolean(formik.errors.role)}
      helperText={formik.touched.role && formik.errors.role}
      sx={{
        width: "100%",
        "&:hover": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "black !important",
          },
        },
      }}
    />,

    <SelectOffice
      label="Office"
      name="officeId"
      width="100%"
      disabled={formik.values.role === "admin"}
      value={formik.values.officeId}
      onChange={(fieldName, selectedValue) => {
        formik.setFieldValue("officeId", selectedValue);
      }}
      onBlur={formik.handleBLur}
      error={formik.touched.officeId && Boolean(formik.errors.officeId)}
      helperText={formik.touched.officeId && formik.errors.officeId}
      sx={{
        width: "100%",
        "&:hover": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "black !important",
          },
        },
      }}
    />,

    <SelectUnit
      label="Unit"
      name="unitId"
      width="100%"
      disabled={formik.values.role === "admin"}
      value={formik.values.unitId}
      onChange={(fieldName, selectedValue) => {
        formik.setFieldValue("unitId", selectedValue.id);
      }}
      onBlur={formik.handleBLur}
      error={formik.touched.unitId && Boolean(formik.errors.unitId)}
      helperText={formik.touched.unitId && formik.errors.unitId}
      showSuperintendents
      sx={{
        width: "100%",
        "&:hover": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "black !important",
          },
        },
      }}
    />,
  ];

  const chunkArray = (componentsArray, chunkSize) => {
    const result = [];
    for (let i = 0; i < componentsArray.length; i += chunkSize) {
      result.push(componentsArray.slice(i, i + chunkSize));
    }
    return result;
  };

  const chunkedComponents = chunkArray(components, 2);

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
        // formik.resetForm();
        // setError("");
      }}
    >
      <Box sx={style}>
        {loading && (
          <Box
            sx={{
              ...style,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 1000,
            }}
          >
            <CircularProgress sx={{ color: "white" }} />
          </Box>
        )}
        <Box
          variant="form"
          component="form"
          onSubmit={formik.handleSubmit}
          autoComplete="off"
          sx={style}
        >
          <Box>
            <Box // Fixed Header
              sx={{
                position: "sticky",
                top: 0,
                backgroundColor: "#59395c",
                // boxShadow: "3px 2px 20px 3px rgba(0, 0, 0, 0.3)",
                boxShadow: "8px 8px 15px 3px rgba(0, 0, 0, 0.3)",
                borderBottomLeftRadius: "30%",
                borderBottomRightRadius: "200px",
                zIndex: 2,
                px: 4,
                mr: 4,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  py: 2,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "25px",
                    color: "#fff",
                    py: 1,
                  }}
                >
                  Add User
                </Typography>
                <IconButton
                  onClick={handleClose}
                  sx={{ color: "#fff", p: 0, m: 0 }}
                >
                  <CancelIcon />
                </IconButton>
              </Box>
            </Box>
            {error && (
              <Box sx={{ backgroundColor: "red", width: "100%", mt: 2, px: 1 }}>
                <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                  {error}
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                m: 4,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  width: "100%",
                  backgroundColor: "#fff",
                  border: "solid 1px #b6b6b6",
                  p: 2,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: "gray",
                    mr: 2,
                    mb: 2,
                  }}
                >
                  (Please type the name as it is written in the documents)
                </Typography>
                {/* <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    width: "100%",
                    gap: 2,
                    mb: "2.5%",
                  }}
                >
                  <Box sx={{ width: "38%", minWidth: "200px" }}>
                    <TextField
                      name="firstName"
                      label="First Name"
                      size="small"
                      disabled={loading}
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBLur}
                      error={
                        formik.touched.firstName &&
                        Boolean(formik.errors.firstName)
                      }
                      helperText={
                        formik.touched.firstName && formik.errors.firstName
                      }
                      variant="outlined"
                      fullWidth
                    />
                  </Box>
                  <Box sx={{ width: "20%", minWidth: "100px" }}>
                    <TextField
                      name="middleIntl"
                      label="Middle Initial"
                      size="small"
                      disabled={loading}
                      value={formik.values.middleIntl}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBLur}
                      error={
                        formik.touched.middleIntl &&
                        Boolean(formik.errors.middleIntl)
                      }
                      helperText={
                        formik.touched.middleIntl && formik.errors.middleIntl
                      }
                      variant="outlined"
                      fullWidth
                    />
                  </Box>
                  <Box sx={{ width: "38%", minWidth: "200px" }}>
                    <TextField
                      name="lastName"
                      label="Last Name"
                      size="small"
                      disabled={loading}
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBLur}
                      error={
                        formik.touched.lastName &&
                        Boolean(formik.errors.lastName)
                      }
                      helperText={
                        formik.touched.lastName && formik.errors.lastName
                      }
                      variant="outlined"
                      fullWidth
                    />
                  </Box>
                </Box> */}
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    width: "100%",
                    gap: 2,
                    mb: "2.5%",
                  }}
                >
                  <Box sx={{ flex: "1 0 120px" }}>
                    <TextField
                      name="firstName"
                      label="First Name"
                      size="small"
                      disabled={loading}
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBLur}
                      error={
                        formik.touched.firstName &&
                        Boolean(formik.errors.firstName)
                      }
                      helperText={
                        formik.touched.firstName && formik.errors.firstName
                      }
                      variant="outlined"
                      fullWidth
                    />
                  </Box>
                  <Box sx={{ flex: "1 0 100px" }}>
                    <TextField
                      name="middleIntl"
                      label="Middle Initial"
                      size="small"
                      disabled={loading}
                      value={formik.values.middleIntl}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBLur}
                      error={
                        formik.touched.middleIntl &&
                        Boolean(formik.errors.middleIntl)
                      }
                      helperText={
                        formik.touched.middleIntl && formik.errors.middleIntl
                      }
                      variant="outlined"
                      fullWidth
                    />
                  </Box>
                  <Box sx={{ flex: "1 0 120px" }}>
                    <TextField
                      name="lastName"
                      label="Last Name"
                      size="small"
                      disabled={loading}
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBLur}
                      error={
                        formik.touched.lastName &&
                        Boolean(formik.errors.lastName)
                      }
                      helperText={
                        formik.touched.lastName && formik.errors.lastName
                      }
                      variant="outlined"
                      fullWidth
                    />
                  </Box>
                </Box>
                {/* {chunkedComponents.map((component, index) => (
                  <Box
                    sx={{
                      width: index % 2 === 0 ? "47.5%" : "50%",
                      minWidth: "200px",
                      mr: index % 2 === 0 ? "2.5%" : 0,
                      mb: "2.5%",
                    }}
                  >
                    {component}
                  </Box>
                ))} */}
                {/* <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    width: "100%",
                    gap: 2,
                    mb: "2.5%",
                  }}
                >
                  {chunkedComponents.map((component) => ({ component }))}
                </Box> */}
                {chunkedComponents.map((chunk, index) => (
                  <Box
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      width: "100%",
                      gap: 2,
                      mb: "2.5%",
                    }}
                  >
                    {chunk.map((component, idx) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Box key={idx} sx={{ flex: "1 0 200px" }}>
                        {component}
                      </Box>
                    ))}
                  </Box>
                ))}

                <Box
                  sx={{
                    width: "100%",
                    background: "#ebebeb",
                    border: "solid 1px #b6b6b6",
                    p: 2,
                    mb: "2.5%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                    }}
                  >
                    <Box sx={{ width: "90%" }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          alignItems: "center",
                          mb: 2,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            color: "gray",
                            mr: 2,
                          }}
                        >
                          Related Unit/s (Optional):
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            my: 1,
                            "@media (min-width: 880px)": {
                              display: "none",
                            },
                          }}
                        >
                          <Tooltip title="Add Unit" placement="right">
                            <IconButton
                              onClick={() =>
                                setUserRelatedUnits((prev) => [...prev, ""])
                              }
                              sx={{
                                backgroundColor: "#4ea82b",
                                color: "#fff",
                                height: "30px",
                                width: "30px",
                                p: 1,
                                mr: "0.5vw",
                                "&:hover": {
                                  backgroundColor: "#0ed145",
                                },
                              }}
                            >
                              <AddIcon
                                sx={{
                                  fontSize: "15px",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Remove Unit" placement="right">
                            <IconButton
                              disabled={userRelatedUnits.length < 2}
                              onClick={() =>
                                setUserRelatedUnits((prev) => prev.slice(0, -1))
                              }
                              sx={{
                                fontSize: "15px",
                                fontWeight: "bold",
                                backgroundColor:
                                  userRelatedUnits.length < 2
                                    ? "lightgray"
                                    : "red",
                                border:
                                  userRelatedUnits.length < 2 &&
                                  "solid 1px #f28c8c",
                                color:
                                  userRelatedUnits.length < 2
                                    ? "black"
                                    : "#fff",
                                height: "30px",
                                width: "30px",
                                p: 1,
                                mr: 2,
                                "&:hover": {
                                  backgroundColor: "#e54c51",
                                },
                              }}
                            >
                              <RemoveIcon
                                sx={{
                                  fontSize: "15px",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexWrap: "wrap",
                          width: "100%",
                          gap: 2,
                        }}
                      >
                        {userRelatedUnits?.map((unit, index) => (
                          <Box
                            sx={{
                              width:
                                userRelatedUnits.length > 1 ? "48%" : "100%",
                              minWidth: "200px",
                            }}
                          >
                            <SelectUnit
                              label={`Unit ${index + 1}`}
                              disabled={loading}
                              value={unit?.id}
                              onChange={(fieldName, selectedValue) => {
                                handleRelatedUnits(index, selectedValue);
                              }}
                              error={Boolean(unit?.id === "")}
                              helperText={
                                <span style={{ color: "red" }}>
                                  {unitErrorMssg[index]}
                                </span>
                              }
                              onBlur={formik.handleBLur}
                              showSuperintendents
                              sx={{
                                width: "100%",
                                "&:hover": {
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "black !important",
                                  },
                                },
                              }}
                            />
                          </Box>
                        ))}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "end",
                        "@media (max-width: 880px)": {
                          display: "none",
                        },
                      }}
                    >
                      <Tooltip title="Add Unit" placement="right">
                        <IconButton
                          onClick={() =>
                            setUserRelatedUnits((prev) => [...prev, ""])
                          }
                          sx={{
                            backgroundColor: "#4ea82b",
                            color: "#fff",
                            mb: 1,
                            "&:hover": {
                              backgroundColor: "#0ed145",
                            },
                          }}
                        >
                          <AddIcon sx={{ fontWeight: "bold" }} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Remove Unit" placement="right">
                        <IconButton
                          disabled={userRelatedUnits.length < 2}
                          onClick={() =>
                            setUserRelatedUnits((prev) => prev.slice(0, -1))
                          }
                          sx={{
                            backgroundColor:
                              userRelatedUnits.length < 2 ? "lightgray" : "red",
                            border:
                              userRelatedUnits.length < 2 &&
                              "solid 1px #f28c8c",
                            color:
                              userRelatedUnits.length < 2 ? "black" : "#fff",
                            "&:hover": {
                              backgroundColor: "#e54c51",
                            },
                          }}
                        >
                          <RemoveIcon sx={{ fontWeight: "bold" }} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>

                {!disableSignUpload && (
                  <Box
                    sx={{
                      width: "100%",
                      background: "#ebebeb",
                      border: "solid 1px #b6b6b6",
                      p: 2,
                      mb: "2.5%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                      }}
                    >
                      <Box sx={{ width: "90%" }}>
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            alignItems: "center",
                            mb: 2,
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "15px",
                              fontWeight: "bold",
                              color: "gray",
                              mr: 2,
                            }}
                          >
                            Position/s:
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              my: 1,
                              "@media (min-width: 880px)": {
                                display: "none",
                              },
                            }}
                          >
                            <Tooltip title="Add Position" placement="right">
                              <IconButton
                                onClick={() =>
                                  setPositions((prev) => [...prev, ""])
                                }
                                sx={{
                                  backgroundColor: "#4ea82b",
                                  color: "#fff",
                                  height: "30px",
                                  width: "30px",
                                  p: 1,
                                  mr: "0.5vw",
                                  "&:hover": {
                                    backgroundColor: "#0ed145",
                                  },
                                }}
                              >
                                <AddIcon
                                  sx={{
                                    fontSize: "15px",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Remove Position" placement="right">
                              <IconButton
                                disabled={positions.length < 2}
                                onClick={() =>
                                  setPositions((prev) => prev.slice(0, -1))
                                }
                                sx={{
                                  fontSize: "15px",
                                  fontWeight: "bold",
                                  backgroundColor:
                                    positions.length < 2 ? "lightgray" : "red",
                                  border:
                                    positions.length < 2 && "solid 1px #f28c8c",
                                  color:
                                    positions.length < 2 ? "black" : "#fff",
                                  height: "30px",
                                  width: "30px",
                                  p: 1,
                                  mr: 2,
                                  "&:hover": {
                                    backgroundColor: "#e54c51",
                                  },
                                }}
                              >
                                <RemoveIcon
                                  sx={{
                                    fontSize: "15px",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Box>
                        <Box sx={{ mb: 2 }}>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: "bold",
                              color: "gray",
                              mr: 2,
                            }}
                          >
                            (Please add all possible position/s even the ones
                            specified above)
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: "bold",
                              color: "gray",
                              mr: 2,
                            }}
                          >
                            (Please add the position/s as written in the
                            documents)
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexWrap: "wrap",
                            width: "100%",
                            gap: 2,
                          }}
                        >
                          {positions?.map((position, index) => (
                            <Box
                              sx={{
                                width: positions.length > 1 ? "48%" : "100%",
                                minWidth: "200px",
                              }}
                            >
                              <TextField
                                label={`Position ${index + 1}`}
                                disabled={loading}
                                size="small"
                                value={position}
                                onChange={(evt) => {
                                  handlePositions(index, evt.target.value);
                                }}
                                error={Boolean(positionErrorMssg[index])}
                                helperText={
                                  <span style={{ color: "red" }}>
                                    {positionErrorMssg[index]}
                                  </span>
                                }
                                fullWidth
                              />
                            </Box>
                          ))}
                        </Box>
                        {/* <Grid
                      container
                      spacing={2}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      {destinations?.map((destination, index) => (
                        <Grid item xs={destinations.length > 1 ? 6 : 12}>
                          <SelectUnit
                            label={`Destination ${index + 1}`}
                            disabled={loading}
                            value={destination?.id}
                            onChange={(fieldName, selectedValue) => {
                              handleDestinations(index, selectedValue);
                            }}
                            error={Boolean(destination?.id === "")}
                            helperText={
                              <span style={{ color: "red" }}>
                                {unitErrorMssg[index]}
                              </span>
                            }
                            restrictOwnUnit
                            sx={{
                              width: "100%",
                              pr: 3,
                              "&:hover": {
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "black !important",
                                },
                              },
                            }}
                          />
                        </Grid>
                      ))}
                    </Grid> */}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          textAlign: "end",
                          "@media (max-width: 880px)": {
                            display: "none",
                          },
                        }}
                      >
                        <Tooltip title="Add Position" placement="right">
                          <IconButton
                            onClick={() =>
                              setPositions((prev) => [...prev, ""])
                            }
                            sx={{
                              backgroundColor: "#4ea82b",
                              color: "#fff",
                              mb: 1,
                              "&:hover": {
                                backgroundColor: "#0ed145",
                              },
                            }}
                          >
                            <AddIcon sx={{ fontWeight: "bold" }} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Remove Position" placement="right">
                          <IconButton
                            disabled={positions.length < 2}
                            onClick={() =>
                              setPositions((prev) => prev.slice(0, -1))
                            }
                            sx={{
                              backgroundColor:
                                positions.length < 2 ? "lightgray" : "red",
                              border:
                                positions.length < 2 && "solid 1px #f28c8c",
                              color: positions.length < 2 ? "black" : "#fff",
                              "&:hover": {
                                backgroundColor: "#e54c51",
                              },
                            }}
                          >
                            <RemoveIcon sx={{ fontWeight: "bold" }} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Box>
                )}

                {signError && (
                  <Box sx={{ backgroundColor: "red", width: "100%", px: 1 }}>
                    <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                      {signError}
                    </Typography>
                  </Box>
                )}
                {!disableSignUpload && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexWrap: "wrap",
                      width: "100%",
                      gap: 2,
                      mb: "2.5%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "200px",
                        width: "200px",
                        backgroundColor: "#fff",
                        border: "solid 1px gray",
                        mr: 2,
                        mb: 2,
                      }}
                    >
                      {signImg ? (
                        <img
                          src={signImg}
                          alt="signature"
                          style={{
                            width: "100%",
                          }}
                        />
                      ) : (
                        <p>No Signature</p>
                      )}
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <Box
                        sx={{
                          background: "#ebebeb",
                          border: "solid 1px #b6b6b6",
                          borderRadius: "4px",
                          width: "100%",
                          mb: 2,
                          p: 2,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            color: "gray",
                            mr: 2,
                          }}
                        >
                          Upload Signature:
                        </Typography>

                        <Box
                          sx={{
                            display: "flex",
                            // justifyContent: "space-between",
                            alignItems: "center",
                            flexWrap: "wrap",
                            mt: 2,
                          }}
                        >
                          <Box
                            sx={{
                              borderRadius: "4px",
                              border: "solid 1px #b6b6b6",
                              width: "25vw",
                              minWidth: "200px",
                              color: sign ? "black" : "#757575",
                              py: "8px",
                              px: "12px",
                              mr: 2,
                            }}
                          >
                            <Typography>
                              {sign ? sign?.name : "No PNG chosen"}
                            </Typography>
                          </Box>
                          <Button
                            htmlFor={loading ? null : "signUpload"}
                            sx={{
                              backgroundColor: "#2f2f2f",
                              color: "#fff",
                              fontSize: "14px",
                              fontWeight: "bold",
                              padding: "5px 20px",
                              borderRadius: "5px",
                              "&:hover": {
                                boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
                                backgroundColor: "#f6e247",
                                color: "#59395c",
                                fontWeight: "bold",
                              },
                            }}
                          >
                            <Typography
                              variant="label"
                              component="label"
                              htmlFor={loading ? null : "signUpload"}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                                fontSize: "small",
                              }}
                            >
                              <UploadIcon sx={{ mr: 1 }} />
                              CHOOSE PNG
                            </Typography>
                            <input
                              id="signUpload"
                              type="file"
                              name="sign_upload"
                              onChange={handleSignUpload}
                              style={{ display: "none" }}
                            />
                          </Button>
                        </Box>
                      </Box>
                    </Box>

                    <Divider
                      sx={{ width: "100%", backgroundColor: "gray", mb: 2 }}
                    />
                  </Box>
                )}

                {initialError && (
                  <Box sx={{ backgroundColor: "red", width: "100%", px: 1 }}>
                    <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                      {initialError}
                    </Typography>
                  </Box>
                )}

                {!disableSignUpload && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexWrap: "wrap",
                      width: "100%",
                      gap: 2,
                      mb: "2.5%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "200px",
                        width: "200px",
                        backgroundColor: "#fff",
                        border: "solid 1px gray",
                        mr: 2,
                        mb: 2,
                      }}
                    >
                      {initialImg ? (
                        <img
                          src={initialImg}
                          alt="initial"
                          style={{
                            width: "100%",
                          }}
                        />
                      ) : (
                        <p>No Initial</p>
                      )}
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <Box
                        sx={{
                          background: "#ebebeb",
                          border: "solid 1px #b6b6b6",
                          borderRadius: "4px",
                          width: "100%",
                          mb: 2,
                          p: 2,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            color: "gray",
                            mr: 2,
                          }}
                        >
                          Upload Initial:
                        </Typography>

                        <Box
                          sx={{
                            display: "flex",
                            // justifyContent: "space-between",
                            alignItems: "center",
                            flexWrap: "wrap",
                            mt: 2,
                          }}
                        >
                          <Box
                            sx={{
                              borderRadius: "4px",
                              border: "solid 1px #b6b6b6",
                              width: "25vw",
                              minWidth: "200px",
                              color: initial ? "black" : "#757575",
                              py: "8px",
                              px: "12px",
                              mr: 2,
                            }}
                          >
                            <Typography>
                              {initial ? initial?.name : "No PNG chosen"}
                            </Typography>
                          </Box>
                          <Button
                            htmlFor={loading ? null : "initialUpload"}
                            sx={{
                              backgroundColor: "#2f2f2f",
                              color: "#fff",
                              fontSize: "14px",
                              fontWeight: "bold",
                              padding: "5px 20px",
                              borderRadius: "5px",
                              "&:hover": {
                                boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
                                backgroundColor: "#f6e247",
                                color: "#59395c",
                                fontWeight: "bold",
                              },
                            }}
                          >
                            <Typography
                              variant="label"
                              component="label"
                              htmlFor={loading ? null : "initialUpload"}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                                fontSize: "small",
                              }}
                            >
                              <UploadIcon sx={{ mr: 1 }} />
                              CHOOSE PNG
                            </Typography>
                            <input
                              id="initialUpload"
                              type="file"
                              name="initial_upload"
                              onChange={handleInitialUpload}
                              style={{ display: "none" }}
                            />
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              p: 2,
              zIndex: 10,
            }}
          >
            <Button
              disabled={disabled}
              type="submit"
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: disabled ? "lightgray" : "#59395c",
                color: "#fff",
                py: 1,
                width: "10vw",
                minWidth: "100px",
                "&:hover": {
                  backgroundColor: "#f6e247",
                  color: "#59395c",
                  fontWeight: "bold",
                },
              }}
            >
              <AddIcon
                sx={{
                  mr: 1,
                }}
              />
              Add User
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
