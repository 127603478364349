/* eslint-disable no-await-in-loop */
import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";

import CachedIcon from "@mui/icons-material/Cached";
import CloseIcon from "@mui/icons-material/Close";
import HeightIcon from "@mui/icons-material/Height";

import useAxiosPrivate from "contexts/interceptors/axios";
import { useStateContext } from "contexts/ContextProvider";
import { getDocument } from "pdfjs-dist";
import "pdfjs-dist/build/pdf.worker.entry";
import { enqueueSnackbar } from "notistack";
// import Draggable from "react-draggable";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "98vh",
  width: "98vw",
  bgcolor: "background.paper",
  boxShadow: "3px 2px 20px 3px rgba(0, 0, 0, 0.3)",
  borderRadius: "10px",
  overflowY: "auto",
  p: 2,
};

export default function ManualSignModal({
  open,
  handleClose,
  pdfUrl,
  loadingState,
  setOpenViewModal,
  dataFromActions,
  setDataFromActions,
  updateTableFunction,
}) {
  const { auth, BASE_URL } = useStateContext();
  const axiosPrivate = useAxiosPrivate(null);
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });
  const [page, setPage] = useState(1);
  const [signSize, setSignSize] = useState("large");
  const [dragging, setDragging] = useState(false);
  const [emphasize, setEmphasize] = useState(false);
  const [signType, setSignType] = useState("sign");
  const [sign, setSign] = useState();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [images, setImages] = useState([]);
  const [pageSize, setPageSize] = useState({});
  const imgRef = useRef(null);
  const signRef = useRef(null);

  // const handleClick = (e) => {
  //   if (!imgRef.current) return;
  //   const rect = imgRef.current.getBoundingClientRect();
  //   const x = e.clientX - rect.left;
  //   const y = e.clientY - rect.top;
  //   setCoordinates({ x, y });
  // };

  const parsedRole = auth?.role ? JSON.parse(auth?.role) : null;

  const handleSignDocu = async () => {
    if (coordinates) {
      const confirmed = window.confirm(
        "Are you sure you want to sign this document?"
      );

      if (confirmed) {
        setLoading(true);
        const { id, fileId } = dataFromActions;

        let destinations = [];

        if (auth.unitId === 1) {
          destinations = [{ id: 4, unit: "OSDS - Secretary" }];
        } else if (auth.unitId === 2) {
          destinations = [{ id: 7, unit: "OASDS - Secretary" }];
        }

        await axiosPrivate
          .put(`/documents/signWithCoordinates/${id}`, {
            fileId,
            page,
            signSize,
            x: coordinates.x / (pageSize.width || 1),
            y: coordinates.y / (pageSize.height || 1),
            signedBy: { id: auth?.unitId, unit: auth?.unitName },
            signPath: sign,
            // eslint-disable-next-line no-nested-ternary
            status: auth?.unitId === 1 || auth?.unitId === 2 ? 1 : 3,
            ...(!parsedRole?.some((role) =>
              ["unit head", "chief"].includes(role)
            ) && {
              destinations: destinations ? JSON.stringify(destinations) : null,
              lastSource: { id: auth?.unitId, unit: auth?.unitName },
            }),
            remarks: `${signType === "sign" ? "Signed" : "Initialized"} by ${
              auth?.firstName
            } ${auth?.middleIntl ? `${auth?.middleIntl}. ` : ""}${
              auth?.lastName
            } from ${auth?.unitName}`,
          })
          .then(() => {
            enqueueSnackbar("Document Signed", {
              variant: "success",
            });
            setOpenViewModal(false);
            setDataFromActions(null);
            setCoordinates({ x: 0, y: 0 });
            updateTableFunction();
            handleClose();
          })
          .catch((err) => {
            console.log(err);

            setError(err.response.data.error || "Error: Something went wrong");
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    setLoading(loadingState);
  }, [loadingState]);

  useEffect(() => {
    const parsedSignPath = auth.signPath ? JSON.parse(auth.signPath) : null;

    if (auth.unitId === 1) {
      setSign(parsedSignPath ? parsedSignPath[0]?.sign : null);
      setSignType("sign");
    } else {
      setSign(parsedSignPath ? parsedSignPath[0]?.initial : null);
      setSignType("initial");
    }

    const maxPage = dataFromActions?.numberOfPages;

    if (!pdfUrl) {
      setError("PDF URL is undefined.");
      return;
    }
    setError("");

    const loadingTask = getDocument({ url: pdfUrl });

    loadingTask.promise
      .then(async (pdf) => {
        const imagesContainer = [];
        const sizeContainer = {};

        // eslint-disable-next-line no-plusplus
        for (let pageNum = 1; pageNum <= maxPage; pageNum++) {
          const currentPage = await pdf.getPage(pageNum);
          const viewport = currentPage.getViewport({ scale: 1 });
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");

          canvas.width = viewport.width;
          canvas.height = viewport.height;

          sizeContainer[pageNum] = {
            width: canvas.width,
            height: canvas.height,
          };

          await currentPage.render({
            canvasContext: context,
            viewport,
          }).promise;

          const imgDataUrl = canvas.toDataURL("image/png");
          imagesContainer.push(imgDataUrl);
        }

        setImages(imagesContainer);
        setPageSize(sizeContainer);
      })
      .catch((err) => {
        console.error("Error loading PDF:", err);
        setError("Error loading PDF");
      });
  }, [pdfUrl, dataFromActions]);

  const handlePageChange = (evt) => {
    const maxPage = dataFromActions?.numberOfPages;
    if (evt === "next") {
      if (page === maxPage) {
        setPage(maxPage);
      } else {
        setPage(page + 1);
      }
    } else if (evt === "prev") {
      if (page === 1) {
        setPage(1);
      } else {
        setPage(page - 1);
      }
    } else {
      setPage(1);
    }
  };

  const signUrl = `${BASE_URL}${sign}`;

  const move = (e) => {
    e.preventDefault(); // Prevent default action while dragging
    const chosenElement = signRef.current;

    const throttle = (fn, limit) => {
      let inThrottle;

      function throttledFunction(...args) {
        if (!inThrottle) {
          fn.apply(this, args);
          inThrottle = true;
          setTimeout(() => {
            inThrottle = false;
          }, limit);
        }
      }

      return throttledFunction;
    };

    if (chosenElement) {
      const handleMouseMove = throttle((moveEvent) => {
        setDragging(true);

        const rect = imgRef.current.getBoundingClientRect(); // Ensure position is relative to the PDF
        const x = moveEvent.clientX - rect.left;
        const y = moveEvent.clientY - rect.top;

        // Constrain within the bounds of the image
        if (
          x >= 0 &&
          x <= rect.width - chosenElement.clientWidth &&
          y >= 0 &&
          y <= rect.height - chosenElement.clientHeight
        ) {
          chosenElement.style.left = `calc(${x}px - ${
            signSize === "large" ? "28px" : "15px"
          })`;
          chosenElement.style.top = `calc(${y}px - ${
            signSize === "large" ? "20px" : "10px"
          })`;
          setCoordinates({ x, y }); // Update coordinates dynamically
        }
      }, 50);

      const handleMouseUp = () => {
        document.removeEventListener("mousemove", handleMouseMove); // Stop moving after mouse release
        document.removeEventListener("mouseup", handleMouseUp); // Clean up the event listener

        setDragging(false);
      };

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }
  };

  useEffect(() => {
    if (open) {
      setEmphasize(true);

      setTimeout(() => {
        setEmphasize(false);
      }, 2000);
    }
  }, [open]);

  useEffect(() => {
    const parsedSignPath = auth.signPath ? JSON.parse(auth.signPath) : null;

    if (signType === "sign") {
      setSign(parsedSignPath ? parsedSignPath[0]?.sign : null);
    } else if (signType === "initial") {
      setSign(parsedSignPath ? parsedSignPath[0]?.initial : null);
    }
  }, [signType]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <Typography sx={{ fontWeight: "bold", mr: 1 }}>Title:</Typography>
            <Typography>{dataFromActions?.title}</Typography>
          </Box>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        {loading && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              height: "100vh",
              width: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1000,
            }}
          >
            <CircularProgress sx={{ color: "white" }} />
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography>Select signature position</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ fontWeight: "bold", mr: 2 }}>Page: </Typography>
              <Box
                sx={{
                  borderRadius: "4px",
                  border: "solid 1px #b6b6b6",
                  width: "5vw",
                  minWidth: "80px",
                  color: page ? "black" : "#757575",
                  py: "8px",
                  px: "12px",
                }}
              >
                <Typography>
                  {`${page} / ${dataFromActions?.numberOfPages}` || "Page Num"}
                </Typography>
              </Box>
              <IconButton
                onClick={() => handlePageChange("prev")}
              >{`<`}</IconButton>
              <IconButton
                onClick={() => handlePageChange("next")}
              >{`>`}</IconButton>
            </Box>
            <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ fontWeight: "bold", mr: 2 }}>
                Signature size:
              </Typography>
              <Box
                sx={{
                  borderRadius: "4px",
                  border: "solid 1px #b6b6b6",
                  width: "5vw",
                  minWidth: "100px",
                  color: signSize ? "black" : "#757575",
                  py: "8px",
                  px: "12px",
                }}
              >
                <Typography>{signSize || "Sign size"}</Typography>
              </Box>
              <IconButton
                sx={{ mr: 2 }}
                onClick={() => {
                  if (signSize === "large") {
                    setSignSize("small");
                  } else {
                    setSignSize("large");
                  }
                }}
              >
                <HeightIcon />
              </IconButton>
            </Box>
            <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />

            <Typography sx={{ fontWeight: "bold", mr: 2 }}>
              Sign with:
            </Typography>
            <Box
              sx={{
                borderRadius: "4px",
                border: "solid 1px #b6b6b6",
                width: "5vw",
                minWidth: "80px",
                color: page ? "black" : "#757575",
                py: "8px",
                px: "12px",
                mr: 2,
              }}
            >
              <Typography>{`${signType}` || "Page Num"}</Typography>
            </Box>
            <Tooltip title="Change sign" placement="top">
              <IconButton
                sx={{
                  backgroundColor: "gray",
                  color: "#fff",
                  mr: 2,
                  "&:hover": {
                    backgroundColor: "green",
                  },
                }}
                onClick={() => {
                  if (signType === "initial") {
                    setSignType("sign");
                  } else {
                    setSignType("initial");
                  }
                }}
              >
                <CachedIcon />
              </IconButton>
            </Tooltip>
          </Box>
          {/* <TextField
            label="Specify Page"
            type="number"
            size="small"
            value={page}
            onChange={handlePageChange}
            sx={{ width: "10vw", minWidth: "100px" }}
          /> */}
          <Button
            onClick={handleSignDocu}
            disabled={!coordinates}
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: !coordinates ? "lightgray" : "#59395c",
              color: "#fff",
              py: 1,
              width: "10vw",
              minWidth: "200px",
              mr: 2,
              "&:hover": {
                backgroundColor: "#f6e247",
                color: "#59395c",
                fontWeight: "bold",
              },
            }}
          >
            {auth.unitId === 1 ? "Sign Document" : "Affix Initial"}
          </Button>
        </Box>
        {error && (
          <Box sx={{ backgroundColor: "red", width: "100%", mt: 2, px: 1 }}>
            <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
              {error}
            </Typography>
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
            mt: 2,
            position: "relative",
          }}
        >
          {pdfUrl ? (
            <Box
              sx={{
                height: "100%",
                position: "relative",
              }}
            >
              <Box
                ref={imgRef}
                // onClick={handleClick}
                sx={{
                  height: "auto",
                  position: "relative",
                }}
              >
                {coordinates &&
                  pageSize[page] &&
                  (sign ? (
                    <Box
                      sx={{
                        position: "absolute",
                        display: "inline-block",
                      }}
                    >
                      {emphasize && (
                        <Box
                          sx={{
                            position: "absolute",
                            left: "-80px", // Adjust this to control the distance from the signature
                            top: "10px",
                            transform: "translateY(-50%)",
                            backgroundColor: "rgba(0, 0, 0, 0.7)",
                            color: "white",
                            padding: "4px 8px",
                            borderRadius: "4px",
                            fontSize: "12px",
                            border: "solid 1px black",
                          }}
                        >
                          Drag ME!
                        </Box>
                      )}

                      <Box
                        ref={signRef}
                        onMouseDown={move}
                        sx={{
                          position: "absolute",
                          // left: `calc(${coordinates.x}px - ${
                          //   signSize === "large" ? "28px" : "15px"
                          // })`,
                          // top: `calc(${coordinates.y}px - ${
                          //   signSize === "large" ? "20px" : "10px"
                          // })`,
                          transition: "transform 2s ease",
                          transform: emphasize ? "scale(1.5)" : "scale(1)",
                          width: signSize === "large" ? "55px" : "30px",
                          height: signSize === "large" ? "40px" : "20px",
                          backgroundColor: "rgba(255, 0, 0, 0.3)",
                          borderRadius: "10px",
                          cursor: dragging ? "grabbing" : "grab",
                          "&:hover": { border: "solid 1px gray" },
                        }}
                      >
                        <img
                          src={signUrl}
                          alt="signature"
                          style={{
                            width: "100%",
                          }}
                        />
                      </Box>
                    </Box>
                  ) : (
                    <Tooltip
                      title={emphasize ? "Drag Me!" : ""}
                      placement="left"
                      disableHoverListener={!emphasize}
                    >
                      <Box
                        ref={signRef}
                        onMouseDown={move}
                        style={{
                          position: "absolute",
                          // left: `calc(${coordinates.x}px - ${
                          //   signSize === "large" ? "28px" : "15px"
                          // })`,
                          // top: `calc(${coordinates.y}px - ${
                          //   signSize === "large" ? "20px" : "10px"
                          // })`,
                          width: signSize === "large" ? "55px" : "30px",
                          height: signSize === "large" ? "40px" : "20px",
                          backgroundColor: "rgba(255, 0, 0, 0.6)",
                          borderRadius: "10px",
                          cursor: dragging ? "grabbing" : "grab",
                          "&:hover": { border: "solid 1px gray" },
                        }}
                      />
                    </Tooltip>
                  ))}
                <img
                  src={images[page - 1]}
                  alt={`Page ${page}`}
                  style={{ border: "solid 1px black", marginBottom: "10px" }}
                />
              </Box>
            </Box>
          ) : (
            <Typography>No PDF available</Typography>
          )}
        </Box>
      </Box>
    </Modal>
  );
}
