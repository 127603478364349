/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useStateContext } from "contexts/ContextProvider";
import useAxiosPrivate from "contexts/interceptors/axios";

import DocumentsTable from "../../DocumentsTable";

export default function OutgoingDocuments() {
  const { auth } = useStateContext();
  const axiosPrivate = useAxiosPrivate();
  const [documents, setDocuments] = useState([]);
  const [selectedData, setSelectedData] = useState(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const SDSSecIds = [3, 4];
  const ASDSSecIds = [7];

  const handleGetAll = () => {
    setLoading(true);
    setError("");

    axiosPrivate
      .get(`/documents/getAllDocuments`, {
        params: { unitId: auth.unitId },
      })
      .then((res) => {
        res.data.forEach((document) => {
          if (typeof document.action === "string") {
            document.action = JSON.parse(document.action);
          }
        });

        const ownedDocs = res.data.filter(
          (doc) => auth.unitId === doc.currentOwner
        );

        const filteredOutgoingDocs = ownedDocs.filter((doc) => {
          const lastSources = JSON.parse(doc?.lastSource);
          const destinations = JSON.parse(doc?.destinations);

          let lastSource;

          if (lastSources.length === 1) {
            lastSource = lastSources ? lastSources[0].id : null;
          } else {
            lastSource = lastSources ? lastSources[1].id : null;
          }

          if (SDSSecIds.includes(auth.unitId)) {
            if (
              destinations.some((dest) => dest.id === 1) &&
              SDSSecIds.includes(doc.currentOwner) &&
              (doc.status === 7 || doc.status === 8)
            ) {
              return true;
            }
          } else if (ASDSSecIds.includes(auth.unitId)) {
            if (
              destinations.some((dest) => dest.id === 2) &&
              ASDSSecIds.includes(doc.currentOwner) &&
              (doc.status === 7 || doc.status === 8)
            ) {
              return true;
            }
          }

          // if (doc.primSourceId === auth.unitId || lastSource === auth.unitId) {
          //   if (doc.status === 1 || doc.status === 8) {
          //     console.log("1 8: ", doc.id, lastSource);
          //   }
          //   console.log("lastSource: ", doc.id, lastSource);
          // }
          return (
            (doc.primSourceId === auth.unitId || lastSource === auth.unitId) &&
            (doc.status === 1 || doc.status === 8) &&
            destinations.some((dest) => dest.id !== auth.unitId)
          );
        });

        // Sort documents by complexity (3 - 1)
        filteredOutgoingDocs.sort((a, b) => b.complexity - a.complexity);

        setDocuments(filteredOutgoingDocs);
      })
      .catch((err) => {
        setError(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetAll();
  }, []);

  // useEffect(() => {
  //   if (selectedData) {
  //     if (selectedData?.function === "edit") {
  //       setOpenEditModal(selectedData);
  //     }
  //     // if (selectedData?.function === "viewActionsReqs") {
  //     //   setOpenViewActionsModal(true);
  //     // }
  //     // if (selectedData?.function === "view") {
  //     //   handleViewFile(selectedData);
  //     // }
  //   }
  // }, [selectedData]);

  return (
    <Box
      sx={{
        overflowX: "auto",
        p: "20px",
        width: "100%",
      }}
    >
      <Box
        sx={{
          textAlign: "center",
          borderRadius: "10px",
          boxShadow: "3px 2px 15px 3px rgba(100, 100, 100, 0.5)",
          // backgroundColor: "#59395c",
          backgroundColor: "#f0f0f0",
          p: "1rem",
          mb: "1rem",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            color: "#59395c",
            textTransform: "uppercase",
            fontSize: "25px",
          }}
        >
          Outgoing Documents
        </Typography>
      </Box>
      {error && (
        <Box sx={{ backgroundColor: "red", width: "100%", mt: 2, px: 1 }}>
          <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
            {error}
          </Typography>
        </Box>
      )}
      <Box
        borderRadius="10px"
        boxShadow="3px 2px 15px 3px rgba(100, 100, 100, 0.8)"
        p="2rem"
        sx={{ backgroundColor: "#f0f0f0" }}
      >
        <Box
          sx={{
            mt: 2,
            minWidth: "100%",
          }}
        >
          <DocumentsTable
            data={documents}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            loadingState={loading}
            setLoadingState={setLoading}
            updateTableFunction={handleGetAll}
          />
        </Box>
      </Box>
    </Box>
  );
}
