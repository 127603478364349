/* eslint-disable no-alert */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";

import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import CheckIcon from "@mui/icons-material/Check";

import { useStateContext } from "contexts/ContextProvider";
import useAxiosPrivate from "contexts/interceptors/axios";
import ReturnDocumentModal from "modals/documents/ReturnDocumentModal";
import AcceptDocumentsModal from "modals/documents/AcceptDocumentsModal";
import { enqueueSnackbar } from "notistack";
import DocumentsTable from "../../DocumentsTable";

export default function IncomingDocuments() {
  const { auth } = useStateContext();

  const axiosPrivate = useAxiosPrivate();
  const [documents, setDocuments] = useState([]);
  const [selectedData, setSelectedData] = useState(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [openAcceptModal, setOpenAcceptModal] = useState(false);
  const [openReturnModal, setOpenReturnModal] = useState(false);

  const SDSSecIds = [3, 4];
  const ASDSSecIds = [7];
  const manyDocuments = selectedData?.length > 1;

  const parsedRole = auth?.role ? JSON.parse(auth?.role) : null;

  const handleGetAll = () => {
    setLoading(true);
    setError("");

    axiosPrivate
      .get(`/documents/getAllDocuments`, {
        params: { unitId: auth.unitId },
      })
      .then((res) => {
        res.data.forEach((doc) => {
          if (typeof doc.action === "string") {
            doc.action = JSON.parse(doc.action);
          }
        });

        // console.log(res.data);

        const filteredIncomingDocs = res?.data?.filter((doc) => {
          const destinations = JSON.parse(doc?.destinations);

          if (SDSSecIds.includes(auth.unitId)) {
            if (
              destinations.some((dest) => SDSSecIds.includes(dest.id)) &&
              !SDSSecIds.includes(doc.currentOwner) &&
              doc.status === 1
            ) {
              return true;
            }
          } else if (ASDSSecIds.includes(auth.unitId)) {
            if (
              destinations.some((dest) => ASDSSecIds.includes(dest.id)) &&
              !ASDSSecIds.includes(doc.currentOwner) &&
              doc.status === 1
            ) {
              return true;
            }
          } else if (
            destinations.some((dest) => dest.id === auth.unitId) &&
            doc.currentOwner !== auth.unitId &&
            doc.status === 1
          ) {
            return true;
          }

          if (doc.status === 2) {
            return (
              destinations.some((dest) => dest.id === auth.unitId) &&
              doc.status === 2
            );
          }

          return false;
        });

        // Sort documents by complexity (3 - 1)
        filteredIncomingDocs.sort((a, b) => b.complexity - a.complexity);

        setDocuments(filteredIncomingDocs);
      })
      .catch((err) => {
        setError(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetAll();
  }, []);

  const handleAccept = () => {
    if (
      selectedData.some((data) => !data.signedDateTime) &&
      parsedRole?.some((role) => ["unit head", "chief"].includes(role))
    ) {
      setOpenAcceptModal(true);
    } else {
      const confirmed = window.confirm(
        `Are you sure you want to accept ${manyDocuments ? "these" : "this"} ${
          manyDocuments ? "documents" : "document"
        }?`
      );

      if (confirmed) {
        setLoading(true);
        setError("");

        axiosPrivate
          .patch(`/documents/acceptDocs`, {
            documents: selectedData,
            updateFields: {
              // eslint-disable-next-line no-nested-ternary
              currentOwner: SDSSecIds.includes(auth.unitId)
                ? 4
                : ASDSSecIds.includes(auth.unitId)
                ? 7
                : auth.unitId,
              status: 3,
              remarks: `Accepted by ${auth?.firstName} ${auth?.lastName} from ${auth?.unitName}`,
            },
          })
          .then(() => {
            handleGetAll();

            enqueueSnackbar("Document Accepted", { variant: "success" });
          })
          .catch((err) => {
            setError(err.response.data.error);
          })
          .finally(() => {
            setLoading(false);
          });
      }
      setSelectedData(null);
    }
  };

  // font "Mont"

  return (
    <Box
      sx={{
        overflowX: "auto",
        p: "20px",
        width: "100%",
      }}
    >
      <AcceptDocumentsModal
        open={openAcceptModal}
        handleClose={() => {
          setSelectedData(null);
          setOpenAcceptModal(false);
        }}
        loadingState={loading}
        selectedData={selectedData || null}
        updateTableFunction={() => handleGetAll()}
      />
      <ReturnDocumentModal
        open={openReturnModal}
        handleClose={() => {
          setSelectedData(null);
          setOpenReturnModal(false);
        }}
        loadingState={loading}
        selectedData={selectedData || null}
        updateTableFunction={() => handleGetAll()}
      />
      <Box
        sx={{
          textAlign: "center",
          borderRadius: "10px",
          boxShadow: "3px 2px 15px 3px rgba(100, 100, 100, 0.5)",
          // backgroundColor: "#59395c",
          backgroundColor: "#f0f0f0",
          p: "1rem",
          mb: "1rem",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            color: "#59395c",
            textTransform: "uppercase",
            fontSize: "25px",
          }}
        >
          Incoming Documents
        </Typography>
      </Box>
      {error && (
        <Box sx={{ backgroundColor: "red", width: "100%", mt: 2, px: 1 }}>
          <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
            {error}
          </Typography>
        </Box>
      )}
      <Box
        borderRadius="10px"
        boxShadow="3px 2px 15px 3px rgba(100, 100, 100, 0.8)"
        p="2rem"
        sx={{ backgroundColor: "#f0f0f0" }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            mt: -0.5,
            minWidth: "30%",
            right: 280,
            zIndex: 1000,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              disabled={
                loading ||
                selectedData?.length === 0 ||
                // (selectedData?.length > 1 && auth?.role === "unit head") ||
                !selectedData
              }
              onClick={() => handleAccept()}
              sx={{
                backgroundColor:
                  loading ||
                  selectedData?.length === 0 ||
                  // (selectedData?.length > 1 && auth?.role === "unit head") ||
                  !selectedData
                    ? "lightgray"
                    : "green",
                color: "#fff",
                fontSize: "14px",
                fontWeight: "bold",
                padding: "5px 20px",
                borderRadius: "15px",
                mr: 2,
                "&:hover": {
                  backgroundColor: "lightgray",
                  color: "#2f2f2f",
                  boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
                },
              }}
            >
              <CheckIcon sx={{ mr: 1 }} />
              Accept
            </Button>
            <Button
              disabled={
                loading ||
                selectedData?.length === 0 ||
                selectedData?.length > 1 ||
                !selectedData ||
                selectedData[0]?.status === 2 ||
                selectedData[0]?.signedDateTime
              }
              onClick={() => setOpenReturnModal(true)}
              sx={{
                backgroundColor:
                  loading ||
                  selectedData?.length === 0 ||
                  selectedData?.length > 1 ||
                  !selectedData ||
                  selectedData[0]?.status === 2 ||
                  selectedData[0]?.signedDateTime
                    ? "lightgray"
                    : "#da2c43",
                color: "#fff",
                fontSize: "14px",
                fontWeight: "bold",
                padding: "5px 20px",
                borderRadius: "15px",
                mr: 2,
                "&:hover": {
                  backgroundColor: "lightgray",
                  color: "#2f2f2f",
                  boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.5)",
                },
              }}
            >
              <KeyboardReturnIcon sx={{ mr: 1 }} />
              Return
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            mt: 2,
            minWidth: "100%",
          }}
        >
          <DocumentsTable
            data={documents}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            showCheckbox
            showMultipleSelection
            loadingState={loading}
            setLoadingState={setLoading}
            updateTableFunction={handleGetAll}
          />
        </Box>
      </Box>
    </Box>
  );
}
