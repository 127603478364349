/* eslint-disable no-await-in-loop */
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CachedIcon from "@mui/icons-material/Cached";
import useAxiosPrivate from "contexts/interceptors/axios";
import { useStateContext } from "contexts/ContextProvider";
import { getDocument } from "pdfjs-dist";
import "pdfjs-dist/build/pdf.worker.entry";
import { enqueueSnackbar } from "notistack";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "98vh",
  width: "98vw",
  bgcolor: "background.paper",
  boxShadow: "3px 2px 20px 3px rgba(0, 0, 0, 0.3)",
  borderRadius: "10px",
  overflowY: "auto",
  p: 2,
};

export default function AutoSignModal({
  open,
  handleClose,
  pdfUrl,
  loadingState,
  setOpenAutoSignModal,
  dataFromActions,
  setDataFromActions,
  updateTableFunction,
}) {
  const { auth, BASE_URL } = useStateContext();
  const axiosPrivate = useAxiosPrivate(null);
  const [page, setPage] = useState(1);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [images, setImages] = useState([]);
  const [showSignature, setShowSignature] = useState(false);
  const [signType, setSignType] = useState("sign");
  const [sign, setSign] = useState();

  const parsedRole = auth?.role ? JSON.parse(auth?.role) : null;

  const handleSignDocu = async () => {
    const confirmed = window.confirm(
      "Are you sure you want to sign this document?"
    );

    if (confirmed) {
      setLoading(true);

      const { id, fileId } = dataFromActions;

      let positions;
      if (typeof auth?.positions === "string") {
        positions = JSON.parse(auth?.positions);
      } else {
        positions = auth?.positions;
      }

      let destinations = [];

      if (auth.unitId === 1) {
        destinations = [{ id: 4, unit: "OSDS - Secretary" }];
      } else if (auth.unitId === 2) {
        destinations = [{ id: 7, unit: "OASDS - Secretary" }];
      }

      try {
        if (auth?.unitId === 1) {
          await axiosPrivate.put(`/documents/signDocument/${id}`, {
            fileId,
            page,
            fullName: `${auth?.firstName} ${
              auth?.middleIntl ? `${auth?.middleIntl}. ` : ""
            }${auth?.lastName}`,
            titles: positions,
            signedBy: { id: auth?.unitId, unit: auth?.unitName },
            signPath: sign,
            status: 1,
            destinations: JSON.stringify(destinations), // added
            lastSource: { id: auth?.unitId, unit: auth?.unitName }, // added
            remarks: `Signed by ${auth?.firstName} ${
              auth?.middleIntl ? `${auth?.middleIntl}. ` : ""
            }${auth?.lastName} from ${auth?.unitName}`,
          });
        } else {
          await axiosPrivate.put(`/documents/initializeDocument/${id}`, {
            fileId,
            page,
            fullName: `${auth?.firstName} ${
              auth?.middleIntl ? `${auth?.middleIntl}. ` : ""
            }${auth?.lastName}`,
            titles: positions,
            initialBy: { id: auth?.unitId, unit: auth?.unitName },
            signPath: sign,
            status: parsedRole?.some((role) =>
              ["unit head", "chief"].includes(role)
            )
              ? 3
              : 1,
            ...(!parsedRole?.some((role) =>
              ["unit head", "chief"].includes(role)
            ) && {
              destinations: destinations ? JSON.stringify(destinations) : null,
              lastSource: { id: auth?.unitId, unit: auth?.unitName },
            }),
            remarks: `Initialized by ${auth?.firstName} ${
              auth?.middleIntl ? `${auth?.middleIntl}. ` : ""
            }${auth?.lastName} from ${auth?.unitName}`,
          });
        }
        enqueueSnackbar("Document Signed", {
          variant: "success",
        });
        updateTableFunction();
        setOpenAutoSignModal(false);
        setShowSignature(false);
        setDataFromActions(null);
      } catch (err) {
        console.log(err);

        setError(err.response.data.error || "Error: Something went wrong");
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    setLoading(loadingState);
  }, [loadingState]);

  useEffect(() => {
    const parsedSignPath = auth.signPath ? JSON.parse(auth.signPath) : null;

    if (auth.unitId === 1) {
      setSign(parsedSignPath ? parsedSignPath[0]?.sign : null);
      setSignType("sign");
    } else {
      setSign(parsedSignPath ? parsedSignPath[0]?.initial : null);
      setSignType("initial");
    }

    const maxPage = dataFromActions?.numberOfPages;

    if (!pdfUrl) {
      setError("PDF URL is undefined.");
      return;
    }
    setError("");

    const loadingTask = getDocument({ url: pdfUrl });

    loadingTask.promise
      .then(async (pdf) => {
        const imagesContainer = [];
        const sizeContainer = {};

        // eslint-disable-next-line no-plusplus
        for (let pageNum = 1; pageNum <= maxPage; pageNum++) {
          const currentPage = await pdf.getPage(pageNum);
          const viewport = currentPage.getViewport({ scale: 1 });
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");

          canvas.width = viewport.width;
          canvas.height = viewport.height;

          sizeContainer[pageNum] = {
            width: canvas.width,
            height: canvas.height,
          };

          await currentPage.render({
            canvasContext: context,
            viewport,
          }).promise;

          const imgDataUrl = canvas.toDataURL("image/png");
          imagesContainer.push(imgDataUrl);
        }

        setImages(imagesContainer);
      })
      .catch((err) => {
        console.error("Error loading PDF:", err);
        setError("Error loading PDF");
      });
  }, [pdfUrl, dataFromActions]);

  useEffect(() => {
    const parsedSignPath = auth.signPath ? JSON.parse(auth.signPath) : null;

    if (signType === "sign") {
      setSign(parsedSignPath ? parsedSignPath[0]?.sign : null);
    } else if (signType === "initial") {
      setSign(parsedSignPath ? parsedSignPath[0]?.initial : null);
    }
  }, [signType]);

  const handlePageChange = (evt) => {
    const maxPage = dataFromActions?.numberOfPages;
    if (evt === "next") {
      if (page === maxPage) {
        setPage(maxPage);
      } else {
        setPage(page + 1);
      }
    } else if (evt === "prev") {
      if (page === 1) {
        setPage(1);
      } else {
        setPage(page - 1);
      }
    } else {
      setPage(1);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <Typography sx={{ fontWeight: "bold", mr: 1 }}>Title:</Typography>
            <Typography>{dataFromActions?.title}</Typography>
          </Box>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        {loading && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              height: "100vh",
              width: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1000,
            }}
          >
            <CircularProgress sx={{ color: "white" }} />
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography>Select a page to sign</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ fontWeight: "bold", mr: 2 }}>Page: </Typography>
            <Box
              sx={{
                borderRadius: "4px",
                border: "solid 1px #b6b6b6",
                width: "5vw",
                minWidth: "80px",
                color: page ? "black" : "#757575",
                py: "8px",
                px: "12px",
                mr: 2,
              }}
            >
              <Typography>
                {`${page} / ${dataFromActions?.numberOfPages}` || "Page Num"}
              </Typography>
            </Box>

            <Tooltip title="Previous Page" placement="top">
              <IconButton
                onClick={() => handlePageChange("prev")}
              >{`<`}</IconButton>
            </Tooltip>
            <Tooltip title="Next Page" placement="top">
              <IconButton
                onClick={() => handlePageChange("next")}
              >{`>`}</IconButton>
            </Tooltip>
            <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />

            <Typography sx={{ fontWeight: "bold", mr: 2 }}>
              Sign with:
            </Typography>
            <Tooltip title="View Signature" placement="top">
              <Box
                onClick={() => {
                  if (showSignature) {
                    setShowSignature(false);
                  } else {
                    setShowSignature(true);
                  }
                }}
                sx={{
                  borderRadius: "4px",
                  border: "solid 1px #b6b6b6",
                  width: "5vw",
                  minWidth: "80px",
                  color: page ? "black" : "#757575",
                  py: "8px",
                  px: "12px",
                  mr: 2,
                  cursor: "pointer",
                }}
              >
                <Typography>{`${signType}` || "Page Num"}</Typography>
              </Box>
            </Tooltip>
            <Tooltip title="Change sign" placement="top">
              <IconButton
                sx={{
                  backgroundColor: "gray",
                  color: "#fff",
                  mr: 2,
                  "&:hover": {
                    backgroundColor: "green",
                  },
                }}
                onClick={() => {
                  if (signType === "initial") {
                    setSignType("sign");
                  } else {
                    setSignType("initial");
                  }
                }}
              >
                <CachedIcon />
              </IconButton>
            </Tooltip>
          </Box>

          {/* <TextField
            label="Specify Page"
            type="number"
            size="small"
            value={page}
            onChange={handlePageChange}
            sx={{ width: "10vw", minWidth: "100px" }}
          /> */}
          <Button
            onClick={handleSignDocu}
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#59395c",
              color: "#fff",
              py: 1,
              width: "10vw",
              minWidth: "200px",
              mr: 2,
              "&:hover": {
                backgroundColor: "#f6e247",
                color: "#59395c",
                fontWeight: "bold",
              },
            }}
          >
            {auth.unitId === 1 ? "Sign Document" : "Affix Initial"}
          </Button>
        </Box>
        {error && (
          <Box sx={{ backgroundColor: "red", width: "100%", mt: 2, px: 1 }}>
            <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
              {error}
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
            mt: 2,
            position: "relative",
          }}
        >
          {pdfUrl ? (
            <Box
              style={{
                height: "100%",
                position: "relative",
              }}
            >
              <img
                src={images[page - 1]}
                alt={`Page ${page}`}
                style={{ border: "solid 1px black", marginBottom: "10px" }}
              />
            </Box>
          ) : (
            <Typography>No PDF available</Typography>
          )}
          <Box
            sx={{
              display: showSignature ? "block" : "none",
              transition: "ease-in-out 5s",
              textAlign: "center",
              position: "absolute",
              top: 0,
              left: 0,
            }}
          >
            <Typography>Signature Preview</Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "200px",
                width: "200px",
                backgroundColor: "#fff",
                border: "solid 1px gray",
                mr: 2,
                mb: 2,
              }}
            >
              {sign ? (
                <img
                  src={`${BASE_URL}${sign}`}
                  alt="Sign Preview"
                  style={{
                    width: "100%",
                  }}
                />
              ) : (
                <p>No Signature</p>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
